import React, { useState } from 'react';
import axios from 'axios';
import { X, Send, MessageSquare } from 'lucide-react';

export default function FeedbackForm() {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');

    try {
      const deviceId = localStorage.getItem('deviceId') || 'unknown';
      await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/feedback', { device_id: deviceId, message });
      setMessage('');
      setStatus('Feedback submitted successfully!');
      setTimeout(() => {
        setIsOpen(false);
        setStatus('');
      }, 2000);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 429) {
        setStatus('You have reached the feedback limit for today.');
      } else {
        setStatus('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 left-4 bg-blue-600 text-white p-3 rounded-full flex items-center shadow-lg hover:bg-blue-700 transition-colors duration-200"
        aria-label="Open feedback form"
      >
        Feedback
        <MessageSquare size={24} className='ml-2' />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white w-full max-w-md rounded-lg shadow-xl overflow-hidden">
            <div className="p-4 bg-gray-100 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">Feedback</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close feedback form"
              >
                <X size={20} />
              </button>
            </div>
            <div className="p-4">
              <pre className="bg-gray-100 p-3 rounded-md text-sm mb-4 overflow-x-auto">
                <code>
                  <span className="text-blue-600">const</span>{' '}
                  <span className="text-purple-600">submitFeedback</span>{' '}
                  <span className="text-blue-600">=</span> (
                  <span className="text-orange-600">message</span>) {'=>'} {'{'}
                  <br />
                  {'  '}
                  <span className="text-green-600">
                    // Your feedback is valuable to us
                  </span>
                  <br />
                  {'  '}
                  <span className="text-blue-600">return</span> api.post(
                  <span className="text-orange-600">'/feedback'</span>,{' '}
                  {'{'}message{'}'});
                  <br />
                  {'}'};
                </code>
              </pre>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-1">
                    Your Feedback:
                  </label>
                  <textarea
                    id="feedback"
                    className="w-full p-2 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter your feedback here..."
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center"
                >
                  <Send size={18} className="mr-2" />
                  Submit Feedback
                </button>
              </form>
              {status && (
                <p className="mt-4 text-center text-sm font-medium text-gray-800">{status}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}