'use client'

import React, { useState } from 'react'
import { domToReact } from 'html-react-parser'
import { Copy, Check } from 'lucide-react'

const CopyButton = ({ content, isInline }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <button
      className={`p-1 bg-gray-700 text-purple-400 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-colors duration-200 ${
        isInline ? 'ml-2 inline-flex items-center' : 'absolute top-2 right-2'
      }`}
      onClick={copyToClipboard}
      aria-label={isCopied ? "Copied" : "Copy to clipboard"}
    >
      {isCopied ? (
        <Check className="w-4 h-4" />
      ) : (
        <Copy className="w-4 h-4" />
      )}
    </button>
  )
}

const options = {
  replace: (domNode) => {
    if (domNode.type === 'tag') {
      const { name, attribs, children } = domNode

      const className = attribs && attribs.class ? attribs.class : ''

      const getIndentLevel = (classStr) => {
        const match = classStr.match(/ql-indent-(\d+)/)
        return match ? parseInt(match[1], 10) : 0
      }

      switch (name) {
        case 'h1':
          return (
            <h1 className="text-2xl sm:text-3xl font-bold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h1>
          )
        
        case 'h2':
          return (
            <h2 className="text-2xl sm:text-3xl font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h2>
          )
        case 'h3':
          return (
            <h3 className="text-lg sm:text-xl font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h3>
          )
        case 'h4':
          return (
            <h4 className="text-base sm:text-lg font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h4>
          )
        case 'p':
          return <p className=" Nokora text-base sm:text-lg mb-1">{domToReact(children, options)}</p>
        case 'pre':
          const preContent = domToReact(children, options)
          return (
            <div className="relative">
              <pre className="bg-gray-800 text-gray-100 p-2 sm:p-4 rounded-lg overflow-x-auto  font-mono whitespace-pre-wrap text-sm sm:text-base">
                {preContent}
              </pre>
              <CopyButton content={preContent} isInline={false} />
            </div>
          )
        case 'code':
          const codeContent = domToReact(children, options)
          return (
            <span className="inline-flex items-center bg-gray-700 text-gray-100 px-1 sm:px-2 py-0.5 sm:py-1 rounded font-mono whitespace-pre-wrap text-sm sm:text-base">
              <code>{codeContent}</code>
              <CopyButton content={codeContent} isInline={true} />
            </span>
          )
        case 'ol':
          return (
              <ol className="list-decimal list-inside Nokora text-base sm:text-lg">
              {domToReact(children, options)}
              </ol>
            )
        case 'ul':
          return <ul className="list-inside Nokora text-base sm:text-lg">{domToReact(children, options)}</ul>
        case 'li':
          const indentLevel = getIndentLevel(className)
          return (
            <li
              className={`Nokora mb-2 text-base sm:text-lg list-disc`}
              style={{ marginLeft: `${indentLevel * 15}px` }}
            >
              {domToReact(children, options)}
            </li>
          )
        case 'a':
          return (
            <a
              href={attribs.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline Nokora text-base sm:text-lg"
            >
              {domToReact(children, options)}
            </a>
          )
        case 'img':
          return (
            <img
              src={attribs.src}
              alt={attribs.alt || ''}
              className="w-full sm:w-64 h-auto my-4 rounded-lg shadow-md"
            />
          )
        case 'br':
          return <br />
        default:
          return <span className="Nokora text-base sm:text-lg">{domToReact(children, options)}</span>
      }
    }
  },
}

export default options