import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Code, BookOpen, PlayCircle, ThumbsUp, LogOut, Terminal, List, ChevronDown, ChevronUp, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';
import Footer from '../Components/Footer';
import Profile from '../Components/Dashboard/Profile';
import ViewAllCoursesModal from '../Components/Modals/ViewAllCoursesModal';
import FeedbackForm from '../Components/Dashboard/FeedbackForm';
import ComingSoonModal from '../Components/Dashboard/ComingSoonModalProps';
import StreakComponent from '../Components/Dashboard/StreakComponent';
import TotalTimeSpent from '../Components/Dashboard/TotalTimeSpent';
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation';

const Dashboard = ({currentStreak, longestStreak }) => {

  const [userProgress, setUserProgress] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [lastActive, setLastActive] = useState(null);
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);
  const [selectedInactiveCourse, setSelectedInactiveCourse] = useState(null);
  const token = localStorage.getItem('token');
  const headers = { 'Authorization': `Bearer ${token}` };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [progressResponse, coursesResponse] = await Promise.all([
          axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/progress', { headers }),
          axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses', { headers })
        ]);
  
        setUserProgress(progressResponse.data.courses);
        setAllCourses(coursesResponse.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleDescription = (e, courseId) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedDescriptions(prev => ({
      ...prev,
      [courseId]: !prev[courseId]
    }));
  };

  const truncateDescription = (description, lines = 3) => {
    const words = description.split(' ');
    const truncated = words.slice(0, lines * 10).join(' ');
    return truncated + (words.length > lines * 10 ? '...' : '');
  };

  const handleCourseClick = (course) => {
    if (!course.status) {
      setSelectedInactiveCourse(course);
      setShowComingSoonModal(true);
    } else {

      navigate(`/modules/${course.id}/course`);

    }
  };

  if (loading) {
    return (
      <CodeLoadingAnimation/>
    );
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  }

  const lastLearnedCourse = userProgress.length > 0 
    ? userProgress.reduce((prev, current) => new Date(prev.last_updated_at) > new Date(current.last_updated_at) ? prev : current)
    : null;

  const startedCourses = userProgress.filter(course => course.progress_percentage > 0 && course.progress_percentage < 100);

  const recommendedCourses = allCourses
    .filter(course => !startedCourses.some(progress => progress.course_id === course.id))
    .slice(0, 2);

  const deviceId = localStorage.getItem('deviceId')
  if(deviceId){
    axios.post(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/last-active`, {
      device_id: deviceId
    })
    .then(response => {
      setLastActive(response.data.lastActive);
    })
    .catch(error => {
      console.error('Error fetching last active time:', error);
    });
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800 font-mono flex flex-col">
      <CodeForCambodiaHeader/>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-4">
            <Terminal className="inline-block mr-2 text-blue-600" />
            <span className="text-orange-500">const</span> <span className="text-blue-600">dashboard</span> <span className="text-orange-500">=</span> <span className="text-blue-600">{'{'}</span>
          </h1>
          <p className="text-base 2xl:text-xl text-gray-600 ml-4 sm:ml-8 mb-4">
            <span className="text-green-600">description:</span> <span className="text-orange-500 Nokora">"កន្លែងគ្រប់គ្រងការធ្វើដំណើរសរសេរកូដរបស់អ្នក។ តាមដានវឌ្ឍនភាព បន្តការរៀនសូត្រ និងស្វែងរកផ្លូវថ្មីៗ។"</span>,
          </p>
          <p className="text-base 2xl:text-xl text-gray-600 ml-4 sm:ml-8 mb-4">
            <span className="text-green-600">lastActive:</span> <span className="text-orange-500">"{new Date(lastActive).toLocaleString('en-US', { timeZone: 'Asia/Phnom_Penh' })}"</span>,
          </p>

          <p className='text-base 2xl:text-xl text-gray-600 ml-4 sm:ml-8 m-4'>
            <TotalTimeSpent/>
          </p>
          <p className='text-base 2xl:text-xl text-gray-600 ml-4 sm:ml-8 mb-4'>
            <StreakComponent 
              currentStreak = {currentStreak} 
              longestStreak = {longestStreak}/>
          </p>
          <a className="text-base 2xl:text-xl text-gray-600 ml-4 sm:ml-8 block sm:inline-block" href="https://t.me/codeforcambodia" target='_blank'>
            <span className="text-green-600">telegramGroup:</span> <span className="text-orange-500">"https://t.me/codeforcambodia"</span>,
          </a>

          <span className="text-blue-600 text-2xl sm:text-3xl font-bold mt-4 block">{'}'}</span>
        </div>
        <FeedbackForm/>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Keep Learning Section */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
            <div className="bg-blue-600 text-white p-4">
              <h3 className="text-xl font-semibold">
                <Code className="inline mr-2" />
                <span className="text-yellow-300">function</span> <span className="text-white Nokora font-normal">បន្តរការរៀន</span>() <span className="text-yellow-300">{'{'}</span>
              </h3>
            </div>
            <div className="p-6 flex-grow">
              {lastLearnedCourse ? (
                <div className='p-2'>
                  <h4 className="font-semibold text-lg lg:text-xl mb-2">{lastLearnedCourse.course_name}</h4>
                  <p className="text-sm lg:text-base text-gray-600 mb-2 Nokora"><span className="text-orange-600">កំពុងរៀន:</span> {lastLearnedCourse.current_lesson_name}</p>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                    <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${lastLearnedCourse.progress_percentage}%` }}></div>
                  </div>
                  <p className="text-sm lg:text-base text-right mb-4">{parseFloat(lastLearnedCourse.progress_percentage.toString()).toFixed(2)}% complete</p>
                  <Link 
                    to={`/courses/${lastLearnedCourse.course_id}/modules/${lastLearnedCourse.current_module_id}/lessons/${lastLearnedCourse.current_lesson_id}`}
                    className="inline-flex items-center justify-center Nokora w-full bg-blue-600 hover:bg-blue-700 text-white  py-2 px-4 rounded transition duration-300"
                  >
                    <PlayCircle className="mr-2" size={16} /> រៀនបន្តរ
                  </Link>
                </div>
              ) : (
                <p className="text-center text-gray-500">No courses started yet</p>
              )}
            </div>
            <div className="bg-gray-100 p-4 lg:text-xl text-sm  text-gray-500"><span className="text-yellow-300">{'}'}</span></div>
          </div>

          {/* Started Courses Section */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
            <div className="bg-green-600 text-white p-4">
              <h3 className="text-xl font-semibold">
                <BookOpen className="inline mr-2" />
                <span className="text-yellow-300">function</span> <span className="text-white Nokora font-normal">ថ្នាក់ធ្លាប់រៀន</span>() <span className="text-yellow-300">{'{'}</span>
              </h3>
            </div>
            <div className="p-6 flex-grow">
              <ul className="space-y-8">
                {startedCourses.map(course => (
                  <li key={course.course_id} className="border-b border-gray-200 pb-4 p-2 transition last:border-b-0">
                    <h4 className="font-semibold mb-2 lg:text-xl ">{course.course_name}</h4>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                      <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${course.progress_percentage}%` }}></div>
                    </div>
                    <p className="text-sm lg:text-base text-gray-600 mb-2 Nokora"><span className="text-orange-600 ">កំពុងរៀន:</span> {course.current_lesson_name}</p>

                    <p className="text-sm lg:text-base text-right">{parseFloat(course.progress_percentage.toString()).toFixed(2)}% complete</p>
                    <Link 
                    to={`/courses/${course.course_id}/modules/${course.current_module_id}/lessons/${course.current_lesson_id}`}
                    className="inline-flex mt-4 items-center justify-center Nokora w-full bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded transition duration-300"
                    >
                      <PlayCircle className="mr-2" size={16} /> រៀនបន្តរ
                    </Link>
                  </li>
                ))}
              </ul>
              {startedCourses.length === 0 && (
                <p className="text-center text-gray-500">No courses in progress</p>
              )}
            </div>
            <div className="bg-gray-100 p-4 lg:text-xl text-sm text-gray-500"><span className="text-yellow-300">{'}'}</span></div>
          </div>

          {/* Recommended Courses Section */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
            <div className="bg-purple-600 text-white p-4">
              <h3 className="text-xl font-semibold">
                <ThumbsUp className="inline mr-2" />
                <span className="text-yellow-300">function</span> <span className="text-white Nokora font-normal">គួររៀនថ្នាក់ទាំងនេះ</span>() <span className="text-yellow-300">{'{'}</span>
              </h3>
            </div>
            <div className="p-6 flex-grow">
              <ul className="space-y-4">
                {recommendedCourses.map(course => (
                  <li key={course.id} className="border-b border-gray-200 pb-4 hover:bg-gray-100 p-2 transition duration-300 last:border-b-0">
                    <div onClick={() => handleCourseClick(course)} className="cursor-pointer">
                      <h4 className="font-semibold mb-1 lg:text-xl">{course.course_name}</h4>
                      <div className="text-sm lg:text-xl text-gray-600 mb-2">
                        <p className='Nokora'>{expandedDescriptions[course.id] ? course.description : truncateDescription(course.description)}</p>
                      </div>
                    </div>
                    {course.description.split(' ').length > 30 && (
                      <button 
                        onClick={(e) => toggleDescription(e, course.id)}
                        className="text-purple-600 Nokora hover:text-purple-800 lg:text-lg z-10 mt-2 flex items-center"
                        aria-expanded={expandedDescriptions[course.id]}
                        aria-controls={`description-${course.id}`}
                      >
                        {expandedDescriptions[course.id] ? (
                          <>
                            បន្ថយ <ChevronUp size={16} className="ml-1" />
                          </>
                        ) : (
                          <>
                            អានបន្ថែម <ChevronDown size={16} className="ml-1" />
                          </>
                        )}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              {recommendedCourses.length === 0 && (
                <p className="text-center text-gray-500">No recommended courses at the moment</p>
              )}
            </div>
            <div className="bg-gray-100 p-4 text-sm lg:text-xl text-gray-500"><span className="text-yellow-300">{'}'}</span></div>
          </div>
        </div>

        <div className="mt-8 text-3xl font-bold text-right text-blue-600">{'};'}</div>

        {/* View All Courses Button */}
        <div className="text-center">
          <a
            href="/courses"
            className="bg-blue-600 Nokora text-white px-6 py-3 rounded-md text-lg lg:text-xl font-medium transition duration-300 ease-in-out inline-flex items-center hover:bg-blue-700"
          >
            <List className="mr-2 " size={20} />
            ថ្នាក់ទាំងអស់
          </a>
        </div>
      </main>

      <Profile/>
      <Footer/>

      {/* Logout Button (Fixed Position) */}
      <button 
        className="fixed bottom-4 right-4 bg-red-600 text-white p-3 rounded-full shadow-lg hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50 transition duration-300 flex items-center justify-center group"
        aria-label="Logout"
        onClick={handleLogout}
      >
        <h1 className='text-white mr-2'> Logout </h1>
        <LogOut size={24} className="group-hover:rotate-12 transition-transform duration-300" />
      </button>

      {/* View All Courses Modal */}
      <ViewAllCoursesModal
        allCourses={allCourses}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />


      <ComingSoonModal
        isOpen={showComingSoonModal}
        onClose={() => setShowComingSoonModal(false)}
        course={selectedInactiveCourse}
      />
    </div>
  );
};

export default Dashboard;