import React from 'react';
import PropTypes from 'prop-types';
import CourseCard from './CourseCard';
import { Code } from 'lucide-react';

function CourseList({ courses }) {
  return (
    <div className="container mx-auto px-4 py-8 font-mono text-blue-800 min-h-screen relative">
      {/* Vertical line */}
      <div className="absolute left-0 top-0 bottom-0 w-px bg-blue-200"></div>

      <div className="mb-8 text-center relative">
        <div className="absolute left-0 right-0 h-px bg-blue-200 top-1/2 transform -translate-y-1/2"></div>
        <span className="relative bg-white px-4">
          <Code className="inline-block mr-2 text-blue-600" size={24} />
          <h1 className="text-3xl font-bold inline-block text-blue-700 Nokora">ថ្នាក់ដែលមាន</h1>
        </span>
      </div>

      <div className="mb-6 opacity-70 border-b border-blue-200 pb-2">
        <span className="text-purple-600">import</span>{' '}
        <span className="text-blue-600">{'{ CourseCard }'}</span>{' '}
        <span className="text-purple-600">from</span>{' '}
        <span className="text-orange-500">'./components'</span>;
      </div>

      <div className="mb-4 border-l-2 border-blue-200 pl-4">
        <span className="text-purple-600">const</span>{' '}
        <span className="text-blue-600 Nokora">ថ្នាក់</span>{' '}
        <span className="text-purple-600">=</span>{' '}
        <span className="text-orange-500">{'() => {'}</span>
      </div>

      <div className="pl-8 mb-4 border-l-2 border-blue-200">
        <span className="text-purple-600">return</span>{' '}
        <span className="text-orange-500">{'('}</span>
      </div>

      <div className="pl-12 relative">
        <div className="absolute left-0 top-0 bottom-0 w-px bg-blue-200"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
          {courses && courses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </div>

      <div className="pl-8 mt-4 border-l-2 border-blue-200">
        <span className="text-orange-500">{')'}</span>;
      </div>

      <div className="mt-4 border-l-2 border-blue-200 pl-4">
        <span className="text-orange-500">{'}'}</span>;
      </div>

      <div className="mt-6 opacity-70 border-t border-blue-200 pt-2">
        <span className="text-purple-600">export default</span>{' '}
        <span className="text-blue-600">CourseList</span>;
      </div>

      {/* Horizontal lines at the bottom */}
      <div className="mt-8 space-y-1">
        <div className="h-px bg-blue-200"></div>
        <div className="h-px bg-blue-200"></div>
        <div className="h-px bg-blue-200"></div>
      </div>
    </div>
  );
}

CourseList.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      course_name: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CourseList;