import React, { useEffect, useState } from 'react'
import { Clock } from 'lucide-react'
import axios from 'axios';

export default function TotalTimeSpent() {
  const [totalTimeSpent, setTotalTimeSpent] = useState()

  const fetchTotalTimeSpent = () => {
    const token = localStorage.getItem('token');
    axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/get-time', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      setTotalTimeSpent(response.data.totalTimeSpent);
    })
    .catch(error => {
      console.error('Error fetching total time spent:', error);
    });
  }

  useEffect(()=> {
    fetchTotalTimeSpent()
  }, [])

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24))
    const hours = Math.floor((seconds % (3600 * 24)) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const parts = []
    if (days > 0) parts.push(`${days}d`)
    if (hours > 0) parts.push(`${hours}h`)
    if (minutes > 0) parts.push(`${minutes}m`)
    if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`)

    return parts.join(' ')
  }

  return (
    <div className="text-base lg:text-xl text-gray-600 mb-4">
      <h2 className="flex items-center">
        <span className="text-green-600">totalTimeSpent:</span> <span className="text-blue-600">{'{'}</span>
      </h2>
      <div className="ml-4 sm:ml-8">
        <p>
          <span className="text-green-600">time:</span> <span className="text-orange-500">"{formatTime(totalTimeSpent)}"</span>,
        </p>

      </div>
      <span className="text-blue-600">{'}'}</span>,
    </div>
  )
}