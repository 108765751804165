import React from 'react';
import { X } from 'lucide-react';

export default function ComingSoonModal({ isOpen, onClose, course }) {
  if (!isOpen || !course) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50" onClick={onClose}>
      <div className="bg-white text-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-blue-600 Nokora">មកដល់ឆាប់ៗ!</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
        </div>
        <div className="bg-gray-100 p-4 rounded-md text-sm font-mono overflow-x-auto mb-4">
          <pre className="whitespace-pre-wrap">
            <code>
{`const ${course.course_name.replace(/\s+/g, '')} = {
  status: 'coming_soon',
  title: '${course.course_name}',
  description: '${course.description}',
  estimatedRelease: 'Soon™'
};

console.log('Stay tuned for updates!');`}
            </code>
          </pre>
        </div>
        <p className="text-gray-600 Nokora">
          វគ្គសិក្សានេះមិនទាន់មាននៅឡើយទេ។ យើងកំពុងខិតខំប្រឹងប្រែងដើម្បីនាំយកវាមកជូនអ្នកក្នុងពេលឆាប់ៗនេះ!
        </p>
        <button 
          onClick={onClose}
          className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
        >
          Got it!
        </button>
      </div>
    </div>
  );
}