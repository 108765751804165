import React, { useState, useEffect, useRef } from 'react';
import parse, { domToReact } from 'html-react-parser';
import {
  Play,
  RefreshCw,
  AlertCircle,
  FileText,
  Code,
  Terminal,
  CheckCircle,
  Loader,
  X,
  Eye,
  HelpCircle
} from 'lucide-react';
import axios from 'axios';
import Confetti from 'react-confetti';
import MonacoEditor from '@monaco-editor/react'; // Import Monaco Editor
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import options from '../../Components/Lessons/OptionsQuill';

export default function CodingLesson({ lesson }) {
  const [code, setCode] = useState(lesson.initial_code || '');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [isCorrect, setIsCorrect] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [inputPrompts, setInputPrompts] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [showInputModal, setShowInputModal] = useState(false);
  const [showExpectedOutput, setShowExpectedOutput] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const outputRef = useRef(null);
  const firstInputRef = useRef(null); // Ref for focusing first input
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    setCode(lesson.initial_code || '');
  }, [lesson]);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    if (inputPrompts.length > 0) {
      setInputValues(Array(inputPrompts.length).fill(''));
    }
  }, [inputPrompts]);

  useEffect(() => {
    if (showInputModal && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [showInputModal]);

  const TabButton = ({ tab, icon: Icon, label }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`py-2 px-4 flex items-center justify-center transition-colors duration-200 text-base ${
        activeTab === tab
          ? 'text-blue-600 border-b-2 border-blue-600'
          : 'text-gray-600 hover:text-blue-600'
      }`}
    >
      <Icon size={20} className="mr-2" />
      <span className="font-medium hidden lg:block">{label}</span>
    </button>
  );
// Inside your executeCode function in CodingLesson component

const executeCode = async (inputs) => {
  try {
    const verificationResult = await verifyCodeAPI(
      code,
      lesson.expected_output || '',
      lesson.language || 'python',
      lesson.version || '3.x',
      inputs
    );

    if (verificationResult.message === 'compiled with emkc') {
      console.log(verificationResult.output === lesson.expected_output)
      if(verificationResult.output === lesson.expected_output){

        setIsCorrect(true);
        setOutput(verificationResult.output);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
      } else {
        setIsCorrect(false);
        setError('Does not match the expected output');
        setOutput(verificationResult.output || '');
      }
    } else if (verificationResult.correct) {
      setIsCorrect(true);
      setOutput(verificationResult.output);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    } else {
      setIsCorrect(false);
      setError(verificationResult.message);
      setOutput(verificationResult.output || '');
    }
  } catch (err) {
    setError(`Error: ${err.response ? err.response.data.message : err.message}`);
    setOutput('');
  } finally {
    setIsLoading(false);
    setShowInputModal(false);
  }
};

// Adjust verifyCodeAPI function to match the backend endpoint
async function verifyCodeAPI(code, expectedOutput, language, version, inputs = []) {
  try {
    const payload = {
      code,
      expectedOutput,
      language,
      version,
      inputs
    };

    console.log('Payload:', payload); // Log the payload for debugging

    const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/compile/verify', payload);
    const data = response.data;

    console.log('API Response:', data); // Log the API response for debugging

    return data;
  } catch (err) {
    console.error('Error verifying code:', err.response ? err.response.data : err.message);
    throw err;
  }
}


  const extractInputPrompts = (codeContent) => {
    let inputRegex;
    const programmingLanguage = lesson.language.toLowerCase();

    if (programmingLanguage === 'c') {
      inputRegex = /scanf\s*\(\s*"([^"]*)"\s*,\s*&?\s*([^);\s]+)\s*\)/g;
    } else if (programmingLanguage === 'java') {
      inputRegex = /scanner\.next(?:Int|Double|Line|Boolean)\s*\(\s*"([^"]*)"\s*\)/g;
    } else {
      inputRegex = /(.*?)\s*=\s*(?:int|float|str|bool|list|tuple|set|dict|complex)?\s*\(?\s*input\(([^)]*)\)\)?/g;
    }

    const prompts = [];
    let match;
    while ((match = inputRegex.exec(codeContent)) !== null) {
      let variableName = match[1].trim();
      let promptText = match[2].trim();
      promptText = promptText.replace(/^['"]|['"]$/g, '');

      prompts.push({ variableName, prompt: promptText });
    }
    return prompts;
  };

  const runCode = async () => {
    setIsLoading(true);
    setOutput('');
    setError(null);
    setIsCorrect(false);
    setShowConfetti(false);
    setActiveTab('output');

    const prompts = extractInputPrompts(code);
    setInputPrompts(prompts);

    if (prompts.length > 0) {
      setShowInputModal(true);
    } else {
      executeCode([]);
    }
  };


  const handleInputsSubmit = () => {
    setIsRunning(true);
    const allInputsFilled = inputValues.every(input => input.trim() !== '');
    if (!allInputsFilled) {
      setError('Please fill in all input fields.');
      return;
    }
    executeCode(inputValues);
    setIsRunning(false);
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
    if (error) setError(null);
  };

  return (
    <div className="flex flex-col bg-white text-gray-800 font-mono lg:h-screen lg:pb-12 lg:px-0 px-2">
      {showConfetti && <Confetti />}
      <CodeForCambodiaHeader />

      <div className="flex-grow flex flex-col lg:flex-row overflow-hidden">
        {/* Tabs for Mobile */}
        <div className="lg:hidden flex justify-between p-2 bg-gray-100 border-b border-gray-200">
          <TabButton tab="description" icon={FileText} label="Instructions" />
          <TabButton tab="ide" icon={Code} label="Code Editor" />
          <TabButton tab="output" icon={Terminal} label="Output" />
        </div>

        {/* Description Section */}
        <div
          className={`w-full lg:w-1/3 flex flex-col border-r border-gray-200 ${
            activeTab !== 'description' && 'hidden lg:flex'
          }`}
        >
          <div className="hidden lg:block flex-none p-2 bg-gray-100 border-b border-gray-200">
            <TabButton tab="description" icon={FileText} label="Instructions" />
          </div>
          <div className="flex-grow overflow-auto p-6 max-h-[calc(100vh-12rem)]">
            <div className="prose max-w-none text-base overflow-y-auto Nokora">
              {parse(lesson.description || '', options)}
            </div>
          </div>
        </div>

        {/* Code Editor Section */}
        <div
          className={`w-full lg:w-1/3 flex flex-col border-r h-full border-gray-200 ${
            activeTab !== 'ide' && 'hidden lg:flex'
          }`}
        >
          <div className="flex-none flex items-center justify-between p-2 bg-gray-100 border-b border-gray-200">
            <div className="hidden lg:block">
              <TabButton tab="ide" icon={Code} label="Code Editor" />
            </div>
            <div className="flex items-center">
              <span className="text-base font-medium text-gray-600 mr-2">
                {lesson.language || 'Python'}
              </span>
              <span className="text-sm bg-gray-200 text-gray-700 px-2 py-1 rounded">
                v{lesson.version || '3.x'}
              </span>
            </div>
            <button
              className="text-gray-500 hover:text-blue-600 transition-colors duration-200"
              onClick={() => {
                setCode(lesson.initial_code || '')
                setIsRunning(false);
                setIsCorrect(false);
                setIsLoading(false);
                setShowConfetti(false);
                setError(null);
                setOutput('');
              }}
            >
              <RefreshCw size={20} />
            </button>
          </div>
          <div className="flex-grow relative flex overflow-hidden bg-white h-screen">
            <MonacoEditor
              height="100%"
              language={lesson.language || 'python'} // Set language dynamically
              value={code}
              theme="vs-light"
              options={{
                fontSize: 14,
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
              }}
              onChange={(value) => setCode(value)}
            />
          </div>
          <div className="flex-none p-4 bg-gray-100 border-t border-gray-200">
            <button
              onClick={runCode}
              disabled={isLoading}
              className={`w-full bg-blue-600 text-white px-4 py-3 rounded text-base hover:bg-blue-700 transition-colors duration-200 font-semibold flex items-center justify-center ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? (
                <RefreshCw size={20} className="mr-2 animate-spin" />
              ) : (
                <Play size={20} className="mr-2" />
              )}
              {isLoading ? 'Running...' : 'Run Code'}
            </button>
          </div>
        </div>

        {/* Output Section */}
        <div
          className={`w-full lg:w-1/3 flex flex-col lg:h-full h-screen  ${
            activeTab !== 'output' && 'hidden lg:flex'
          }`}
        >
          <div className="hidden lg:block flex-none p-2 bg-gray-100 border-b border-gray-200">
            <TabButton tab="output" icon={Terminal} label="Output" />
          </div>
          <div className="flex-grow overflow-hidden bg-black text-green-500 p-4 font-mono relative">
            <div className="flex items-center justify-between mb-2">
              <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <div className="text-xs text-gray-500">Terminal</div>
            </div>
            {isLoading ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                <Loader size={24} className="animate-spin mr-2" />
                <span className="text-base">Running code...</span>
              </div>
            ) : (
              <div ref={outputRef} className="h-full overflow-auto">
                <pre className="whitespace-pre-wrap text-sm font-mono text-green-500">
                  {output}
                </pre>
                {error && (
                  <div className="text-red-500 mt-2 text-sm flex items-center">
                    <AlertCircle size={16} className="mr-1" />
                    {error}
                  </div>
                )}
                {isCorrect && (
                  <div className="text-green-500 mt-2 flex items-center">
                    <CheckCircle size={16} className="mr-1" />
                    Great job! You've completed the exercise successfully.
                  </div>
                )}
              </div>
            )}
          </div>
          {lesson.expected_output && (
            <div className="bg-gray-100  p-4 border-t border-gray-200 flex flex-row justify-between items-center">
              <button
                onClick={() => setShowExpectedOutput(!showExpectedOutput)}
                className="flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-200"
              >
                <Eye size={16} className="mr-2" />
                {showExpectedOutput ? 'Hide' : 'Show'} Expected Output
              </button>
              {showExpectedOutput && (
                <pre className="mt-2 p-2 bg-white border border-gray-300 rounded text-sm whitespace-pre-wrap">
                  {lesson.expected_output}
                </pre>
              )}
              <button
                onClick={() => setShowHelpModal(true)}
                className="ml-2 px-4 py-3 text-base font-semibold Nokora flex items-center"
              >
                <HelpCircle size={20} className="mr-2" />
                កូដអត់ត្រូវ? ជួយ!
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Input Modal */}
      {showInputModal && (
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="input-modal-title"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white rounded-lg w-96 shadow-xl border border-gray-300 overflow-hidden font-mono">
            <div className="flex items-center justify-between bg-gray-100 px-4 py-2 border-b border-gray-300">
              <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-400"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                <div className="w-3 h-3 rounded-full bg-green-400"></div>
              </div>
              <h2 className="text-lg font-semibold text-gray-700">Input</h2>
              <button
                onClick={() => setShowInputModal(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                aria-label="Close modal"
              >
                <X size={20} />
              </button>
            </div>
            <div className="p-6 bg-gray-50">
              <form onSubmit={(e) => e.preventDefault()}>
                {inputPrompts.map((prompt, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-green-600 mb-1 text-sm">
                      {prompt.prompt || `Input ${index + 1}:`}
                    </label>
                    <input
                      type="text"
                      ref={index === 0 ? firstInputRef : null}
                      className={`w-full bg-white text-gray-800 border ${
                        error && inputValues[index].trim() === '' ? 'border-red-500' : 'border-gray-300'
                      } rounded p-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
                      value={inputValues[index] || ''}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      placeholder="Enter your input here"
                      required
                    />
                  </div>
                ))}
                {error && (
                  <div className="text-red-500 mb-4 text-sm">
                    <AlertCircle size={16} className="inline-block mr-1" />
                    {error}
                  </div>
                )}
                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
                    onClick={handleInputsSubmit}
                    disabled={isRunning}
                  >
                    <span className="mr-2 font-mono">{'>'}</span>
                    {isRunning ? 'Running...' : 'Run Code'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Help Modal */}
      {showHelpModal && (
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="help-modal-title"
          onClick={() => setShowHelpModal(false)}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
        >
          <div
            className="bg-gray-400 rounded-lg w-[32rem] shadow-xl border border-gray-700 overflow-hidden font-mono"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between bg-gray-200 px-4 py-2 border-b border-gray-700">
              <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <h2 className="text-lg font-semibold text-green-800">Beta Feature</h2>
              <button
                onClick={() => setShowHelpModal(false)}
                className="text-gray-400 hover:text-gray-800 transition-colors duration-200"
                aria-label="Close modal"
              >
                <X size={20} />
              </button>
            </div>
            <div className="p-6 bg-gray-200">
              <p className="text-green-800 mb-4 Nokora text-lg leading-relaxed">
                $ echo "បច្ចុប្បន្នគេហទំព័រនេះស្ថិតក្នុងកម្រិតបេតា។ ប្រសិនបើអ្នកជួបប្រទះកំហុស ឬជឿថាចម្លើយរបស់អ្នកត្រឹមត្រូវ សូមបន្ត។ យើងកំពុងធ្វើការលើការកែលម្អ និងកោតសរសើរចំពោះការអត់ធ្មត់របស់អ្នក!"
              </p>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowHelpModal(false)}
                  className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition-colors duration-200"
                >
                  $ exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
