import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { User, Mail, Calendar, Users, Edit2, Save, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import CodeLoadingAnimation from '../CodeLoadingAnimation';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUserData(response.data);
        setOriginalUserData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Error fetching user profile');
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { first_name, last_name, age, gender } = userData;
      await axios.put('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/profile', 
        { first_name, last_name, age, gender },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsEditing(false);
      setError(null);
      setOriginalUserData(userData);
    } catch (err) {
      setError('Error updating user profile');
    }
  };

  const handleCancel = () => {
    setUserData(originalUserData);
    setIsEditing(false);
  };

  if (isLoading) {
    return <CodeLoadingAnimation/>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 font-mono">
      <h2 className="2xl:text-2xl text-lg font-bold mb-8">
        <span className="text-orange-500">const</span> <span className="text-blue-600">userProfile</span> <span className="text-orange-500">=</span> {'{'}
      </h2>

      <div className="bg-gray-100 p-6 rounded-lg border border-gray-300 shadow-sm text-base 2xl:text-xl">
        <div className="flex justify-between items-center mb-6">
          <h3 className="2xl:text-xl text-base font-semibold">
            <User className="inline mr-2 text-blue-600" />
            <span className="text-orange-500">function</span> <span className="text-blue-600">displayProfile</span>() {'{'}
          </h3>
          <button
            onClick={() => isEditing ? handleCancel() : setIsEditing(true)}
            className="text-blue-600 hover:text-blue-800 flex items-center"
          >
            {isEditing ? <X className="mr-1" size={18} /> : <Edit2 className="mr-1" size={18} />}
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 2xl:text-xl text-base">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              <span className="text-orange-500">let</span> email <span className="text-orange-500">=</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              disabled={true}
              className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              <span className="text-orange-500">let</span> firstName <span className="text-orange-500">=</span>
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={userData.first_name}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              <span className="text-orange-500">let</span> lastName <span className="text-orange-500">=</span>
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={userData.last_name}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="age" className="block text-sm font-medium text-gray-700">
              <span className="text-orange-500">let</span> age <span className="text-orange-500">=</span>
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={userData.age}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
              <span className="text-orange-500">let</span> gender <span className="text-orange-500">=</span>
            </label>
            <select
              id="gender"
              name="gender"
              value={userData.gender}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          {isEditing && (
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="text-green-400">return</span> updatedProfile();
              </button>
            </div>
          )}
        </form>

        <div className="mt-6 text-sm text-gray-500">{'}'}</div>
      </div>

      <div className="mt-8 2xl:text-2xl text-lg font-bold">{'}'}</div>

      {/* <div className="mt-8">
        <Link to="/courses" className="text-blue-600 hover:text-blue-800">
          ← Check All Courses
        </Link>
      </div> */}
    </div>
  );
};

export default Profile;