import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HelpCircle, CheckCircle, XCircle, ArrowRight, Code, AlertTriangle } from 'lucide-react';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';

export default function QuizLesson({ lesson, user_id }) {
  const [questions, setQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [existingQuizResult, setExistingQuizResult] = useState(null);
  const [retryQuiz, setRetryQuiz] = useState(false);
  const [loading, setLoading] = useState(false);

  const quiz_id = lesson.content_id || lesson.id;

  useEffect(() => {
    try {
      if (typeof lesson.questions === 'string') {
        setQuestions(JSON.parse(lesson.questions));
      } else {
        setQuestions(lesson.questions);
      }

      if (typeof lesson.correct_answers === 'string') {
        setCorrectAnswers(JSON.parse(lesson.correct_answers));
      } else {
        setCorrectAnswers(lesson.correct_answers);
      }
    } catch (error) {
      console.error('Error parsing quiz data:', error);
      setQuestions([]);
      setCorrectAnswers({});
    }
  }, [lesson]);

  useEffect(() => {
    const fetchQuizResult = async () => {
      setLoading(true);
      console.log('Fetching quiz result for quiz_id:', quiz_id);
      try {
        const response = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/quiz-results/${quiz_id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setExistingQuizResult(response.data);
        console.log('Fetched quiz result:', response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No existing quiz result');
        } else {
          console.error('Error fetching quiz result:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (quiz_id) {
      fetchQuizResult();
    } else {
      console.log('Missing user_id or quiz_id for fetching quiz result');
      setLoading(false);
    }
  }, [user_id, quiz_id, retryQuiz]);

  const handleAnswerSelect = (questionKey, answer) => {
    setSelectedAnswers(prev => ({ ...prev, [questionKey]: answer }));
  };

  const handleNextQuestion = () => {
    if (currentQuestion < Object.keys(questions).length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const score = calculateScore();
      const totalQuestions = Object.keys(questions).length;
      const percentage = (score / totalQuestions) * 100;

      if (percentage < 70) {
        setShowModal(true);
      } else {
        setShowResults(true);
      }
    }
  };

  const calculateScore = () => {
    let score = 0;
    Object.keys(selectedAnswers).forEach(key => {
      if (selectedAnswers[key] === correctAnswers[key]) {
        score++;
      }
    });
    return score;
  };

  const saveQuizResult = async () => {
    const score = calculateScore();
    const totalQuestions = Object.keys(questions).length;

    const quizResult = {
      user_id: user_id,
      quiz_id: quiz_id,
      score: score,
      total_questions: totalQuestions,
      completion_status: 1,
    };

    try {
      const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/quiz-results', quizResult, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('Quiz result saved:', response.data);

      const updatedResult = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/quiz-results/${quiz_id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setExistingQuizResult(updatedResult.data);
    } catch (error) {
      console.error('Error saving quiz result:', error);
    }
  };

  useEffect(() => {
    if (showResults) {
      saveQuizResult();
    }
  }, [showResults]);

  const renderQuestion = (questionKey) => {
    const question = questions[questionKey];
    return (
      <div className="space-y-3 sm:space-y-4 Nokora">
        <h3 className="text-base sm:text-lg font-bold text-gray-800 Nokora whitespace-pre-wrap">{question.question}</h3>
        <div className="space-y-2">
          {question.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswerSelect(questionKey, option)}
              className={`w-full text-left p-2 sm:p-3 rounded transition-colors duration-200 Nokora text-sm sm:text-base ${
                selectedAnswers[questionKey] === option
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
            >
              <span className="font-medium mr-2 Nokora">{String.fromCharCode(65 + index)}</span>
              <span className="whitespace-pre-wrap">{option}</span>
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderResults = () => {
    const score = calculateScore();
    const totalQuestions = Object.keys(questions).length;
    const percentage = (score / totalQuestions) * 100;

    const isNewHighScore = !existingQuizResult || score > existingQuizResult.score;

    return (
      <div className="space-y-4 min-h-screen pb-16 Nokora">
<div className={`text-center p-4 sm:p-6 rounded-lg border-2 shadow-md ${
  percentage >= 80 ? 'bg-green-50 border-green-300' :
  percentage >= 60 ? 'bg-yellow-50 border-yellow-300' :
  'bg-red-50 border-red-300'
}`}>
  <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">Quiz Results</h2>
  <div className="flex justify-center items-center space-x-4 mb-4">
    <div className="text-4xl font-bold ">
      <span className={`${
        percentage >= 80 ? 'text-green-600' :
        percentage >= 60 ? 'text-yellow-600' :
        'text-red-600'
      }`}>
        {score}
      </span>
      <span className="text-gray-600"> / {totalQuestions}</span>
    </div>
    <div className={`text-2xl font-bold ${
      percentage >= 80 ? 'text-green-600' :
      percentage >= 60 ? 'text-yellow-600' :
      'text-red-600'
    }`}>
      {percentage.toFixed(2)}%
    </div>
  </div>
  <div className="mb-4">
    <span className="text-lg font-semibold text-gray-700">Grade: </span>
    <span className={`text-2xl font-bold  ${
      percentage >= 90 ? 'text-green-600' :
      percentage >= 80 ? 'text-blue-600' :
      percentage >= 70 ? 'text-yellow-600' :
      percentage >= 60 ? 'text-orange-600' :
      'text-red-600'
    }`}>
      {percentage >= 90 ? 'A' :
       percentage >= 80 ? 'B' :
       percentage >= 70 ? 'C' :
       percentage >= 60 ? 'D' : 'F'}
    </span>
  </div>
  {isNewHighScore ? (
    <div className="mt-4 p-3 bg-green-100 rounded-lg Nokora">
      <p className="text-green-800 font-medium ">
        🎉 Congratulations! នេះជាពិន្ទុខ្ពស់បំផុតសម្រាប់ quiz នេះ!
      </p>
      <p className="text-green-700 mt-2">
        You've achieved a new personal best. Keep up the excellent work!
      </p>
    </div>
  ) : (
    <div className="mt-4 p-3 bg-gray-100 rounded-lg">
      <p className="text-gray-800 font-medium Nokora">
        សោកស្តាយ អ្នកបានពិន្ទុតិចជាងលើកមុន។
      </p>
      <p className="text-gray-700 mt-2">
        Don't be discouraged. Review the material and try again to improve your score.
      </p>
    </div>
  )}
          <button
            onClick={() => {
              setRetryQuiz(false);
              setShowResults(false);
              setCurrentQuestion(0);
              setSelectedAnswers({});
            }}
            className={`mt-6 px-6 py-2 text-white rounded transition-colors duration-200 Nokora text-lg ${
              percentage >= 80 ? 'bg-green-600 hover:bg-green-700' :
              percentage >= 60 ? 'bg-yellow-600 hover:bg-yellow-700' :
              'bg-red-600 hover:bg-red-700'
            }`}
          >
            ប្រលងម្តងទៀត
          </button>

</div>
        {Object.keys(questions).map((questionKey) => (
          <div key={questionKey} className="p-3 sm:p-4 rounded-lg border border-gray-200 bg-white">
            <p className="font-bold text-gray-800 mb-2 text-sm sm:text-base">{questions[questionKey].question}</p>
            <div className="flex items-center space-x-2 mb-1 text-sm">
              <p className="text-gray-600">Your answer:</p>
              <span className={`font-medium ${selectedAnswers[questionKey] === correctAnswers[questionKey] ? 'text-green-600' : 'text-red-600'}`}>
                {selectedAnswers[questionKey]}
              </span>
            </div>
            {selectedAnswers[questionKey] !== correctAnswers[questionKey] && (
              <div className="flex items-center space-x-2 mb-2 text-sm">
                <p className="text-gray-600">Correct answer:</p>
                <span className="font-medium text-green-600">{correctAnswers[questionKey]}</span>
              </div>
            )}
            <div className="mt-1">
              {selectedAnswers[questionKey] === correctAnswers[questionKey] ? (
                <div className="flex items-center text-green-600 text-sm">
                  <CheckCircle size={14} className="mr-1" />
                  <span className="font-medium">Correct</span>
                </div>
              ) : (
                <div className="flex items-center text-red-600 text-sm">
                  <XCircle size={14} className="mr-1" />
                  <span className="font-medium">Incorrect</span>
                </div>
              )}
            </div>
          </div>
        ))}

      </div>
    );
  };

  const renderModal = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl max-w-md w-full font-mono">
          <div className="flex items-center mb-4">
            <AlertTriangle className="text-yellow-500 mr-2" size={20} />
            <h3 className="text-lg sm:text-xl font-bold text-gray-800">Warning: <span className="font-normal Nokora">ពិន្ទុទាប</span></h3>
          </div>
          <div className="bg-gray-100 p-3 sm:p-4 rounded-lg mb-4">
            <code className="text-xs sm:text-sm text-gray-800">
              <span className="text-blue-600">if</span> (score &lt; <span className="text-green-600">70%</span>) {'{'}
              <br />
              &nbsp;&nbsp;<span className="text-purple-600">console.warn</span>(<span className="text-yellow-600 Nokora">"បងគួរតែរើនបន្ថែមទៀត!"</span>);
              <br />
              {'}'}
            </code>
          </div>
          <p className="text-gray-600 mb-4 Nokora text-sm sm:text-base">ពិន្ទុបងគឺក្រោម 70%. តើបងចង់បន្តរទៀត ឬសាកម្តងតេស្តម្តងទៀត?</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                setShowModal(false);
                setCurrentQuestion(0);
                setSelectedAnswers({});
                setRetryQuiz(true);
              }}
              className="px-3 sm:px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-200 text-sm sm:text-base"
            >
              Try Again
            </button>
            <button
              onClick={() => {
                setShowModal(false);
                setShowResults(true);
              }}
              className="px-3 sm:px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 text-sm sm:text-base"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-xl sm:text-2xl font-mono text-blue-600">
          Loading quiz questions<span className="animate-pulse">.</span><span className="animate-pulse delay-100">.</span><span className="animate-pulse delay-200">.</span>
        </div>
      </div>
    );
  }

  if (Object.keys(questions).length === 0) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-xl sm:text-2xl font-mono text-blue-600">
          No quiz questions available.
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-800 pb-20 font-mono flex flex-col bg-white Nokora">
      <CodeForCambodiaHeader />
      <div className="flex-grow w-full p-2 sm:p-4 flex flex-col">
        <div className="max-w-3xl mx-auto w-full relative flex-grow flex flex-col">
          {/* Line number decoration */}
          <div className="absolute left-0 top-0 bottom-0 w-8 sm:w-12 hidden sm:flex flex-col items-end pr-1 sm:pr-2 text-gray-400 text-xs sm:text-sm">
            {Array.from({ length: 20 }, (_, i) => (
              <div key={i} className="leading-5 sm:leading-6">{i + 1}</div>
            ))}
          </div>

          {/* Top decoration */}
          <div className="flex items-center mb-3 sm:mb-4 border-b border-gray-200 pb-2 pl-0 sm:pl-16 text-sm">
            <Code className="text-green-600 mr-1 sm:mr-2" size={16} />
            <span className="text-green-600 mr-1">import</span>
            <span className="text-blue-600">Quiz</span>
            <span className="text-green-600 mx-1">from</span>
            <span className="text-yellow-600">'{lesson.lesson_name || 'Quiz'}'</span>
          </div>

          <div className="pl-0 sm:pl-16 flex-grow flex flex-col">
            <div className="flex items-center mb-3 sm:mb-4">
              <HelpCircle className="text-blue-600 mr-2" size={20} />
              <h2 className="text-xl sm:text-2xl font-bold">Quiz</h2>
            </div>

            {/* Display existing score if it exists and user hasn't chosen to retry */}
            {existingQuizResult && !retryQuiz && (
              <div className={`p-6 mb-6 rounded-lg shadow-md  ${
  existingQuizResult.percentage >= 80 ? 'bg-green-50 border-green-200' :
  existingQuizResult.percentage >= 60 ? 'bg-yellow-50 border-yellow-200' :
  'bg-red-50 border-red-200'
} border-2`}>
  <div className="mb-4 border-b border-gray-300 pb-2">
    <h2 className="text-2xl font-bold text-gray-800 Nokora">ពិន្ទុខ្ពស់បំផុត</h2>
  </div>
  <div className="space-y-3 pl-4">
    <div className="flex items-center">
      <span className="text-gray-600 font-semibold w-32 Nokora">Score:</span>
      <span className="text-2xl font-bold Nokora">{existingQuizResult.score}/{existingQuizResult.total_questions}</span>
    </div>
    <div className="flex items-center Nokora">
      <span className="text-gray-600 font-semibold w-32">Percentage:</span>
      <span className={`text-2xl font-bold ${
        existingQuizResult.percentage >= 80 ? 'text-green-600' :
        existingQuizResult.percentage >= 60 ? 'text-yellow-600' :
        'text-red-600'
      }`}>
        {existingQuizResult.percentage}%
      </span>
    </div>
    <div className="flex items-center Nokora">
      <span className="text-gray-600 font-semibold w-32 ">Grade:</span>
      <span className={`text-xl font-bold ${
        existingQuizResult.percentage >= 80 ? 'text-green-600' :
        existingQuizResult.percentage >= 60 ? 'text-yellow-600' :
        'text-red-600'
      }`}>
        {existingQuizResult.percentage >= 90 ? 'A' :
         existingQuizResult.percentage >= 80 ? 'B' :
         existingQuizResult.percentage >= 70 ? 'C' :
         existingQuizResult.percentage >= 60 ? 'D' : 'F'}
      </span>
    </div>
    <div className="flex items-start Nokora">
      <span className="text-gray-600 font-semibold w-32">Feedback:</span>
      <span className={`text-lg Nokora ${
        existingQuizResult.percentage >= 80 ? 'text-green-600' :
        existingQuizResult.percentage >= 60 ? 'text-yellow-600' :
        'text-red-600'
      }`}>
        {existingQuizResult.percentage >= 80 ? 'អស្ចារ្យណាស់! អ្នកបានធ្វើបានយ៉ាងល្អ។' :
         existingQuizResult.percentage >= 60 ? 'ល្អណាស់ ប៉ុន្តែនៅមានចន្លោះដែលត្រូវកែលម្អ។' :
         'ត្រូវការការអនុវត្តបន្ថែម។ កុំបោះបង់!'}
      </span>
    </div>
  </div>

  <div className="mt-6 text-center">
    <button
      onClick={() => setRetryQuiz(true)}
      className={`px-6 py-2 text-white rounded transition-colors duration-200 Nokora text-lg ${
        existingQuizResult.percentage >= 80 ? 'bg-green-600 hover:bg-green-700' :
        existingQuizResult.percentage >= 60 ? 'bg-yellow-600 hover:bg-yellow-700' :
        'bg-red-600 hover:bg-red-700'
      }`}
    >
      ប្រលងម្តងទៀត
    </button>
  </div>
</div>
            )}

            {/* Show quiz only if no existing result or user chose to retry */}
            {(!existingQuizResult || retryQuiz) && !showResults && (
              <div className="flex-grow flex flex-col justify-between">
                <div className="bg-gray-50 p-3 sm:p-4 rounded-lg border border-gray-200 Nokora">
                  {renderQuestion(Object.keys(questions)[currentQuestion])}
                </div>
                <div className="flex justify-between items-center mt-4">
                  <p className="text-gray-600 font-medium text-sm sm:text-base">
                    Question {currentQuestion + 1} of {Object.keys(questions).length}
                  </p>
                  <button
                    onClick={handleNextQuestion}
                    className="bg-blue-600 text-white px-4 sm:px-6 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200 flex items-center font-medium text-sm sm:text-base"
                  >
                    {currentQuestion === Object.keys(questions).length - 1 ? 'Finish' : 'Next'}
                    <ArrowRight className="ml-1" size={16} />
                  </button>
                </div>
              </div>
            )}

            {/* Show results after quiz completion */}
            {showResults && renderResults()}
          </div>

          {/* Bottom decoration */}
          <div className="mt-4 pl-0 sm:pl-16 border-t border-gray-200 pt-2 text-sm">
            <span className="text-green-600">export default</span>
            <span className="text-blue-600 ml-1">Quiz</span>
          </div>
        </div>
      </div>
      {showModal && renderModal()}
    </div>
  );
}