import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AlertCircle, Home } from 'lucide-react';
import axios from 'axios';
import DocumentLesson from './Contents/DocumentLesson';
import CodingLesson from './Contents/CodingLesson';
import QuizLesson from './Contents/QuizLesson';
import LessonNavigation from '../Components/Lessons/LessonNavigation';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';
import WebDevLesson from './Contents/WebDevLesson';
import ChatBot from '../Components/Lessons/ChatBot';
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation';

const LessonContentPage = () => {
  const [lesson, setLesson] = useState(null);
  const [modules, setModules] = useState([]);
  const [currentModule, setCurrentModule] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProgress, setUserProgress] = useState(null); // State to store user's progress
  const { courseId, moduleId, lessonId } = useParams();
  const navigate = useNavigate();

  const fetchModules = async (courseId) => {
    const response = await fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules`);
    if (!response.ok) {
      throw new Error('Failed to fetch modules data');
    }
    const modulesData = await response.json();
    if (!Array.isArray(modulesData) || modulesData.length === 0) {
      throw new Error('No modules found');
    }
    return modulesData.sort((a, b) => a.module_index - b.module_index);
  };

  const fetchLessons = async (courseId, moduleId) => {
    const response = await fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons`);
    if (!response.ok) {
      throw new Error('Failed to fetch lessons data');
    }
    const lessonsData = await response.json();
    if (!Array.isArray(lessonsData)) {
      throw new Error('Invalid lessons data');
    }
    return lessonsData.sort((a, b) => a.lesson_index - b.lesson_index);
  };

  const fetchLessonContent = async (courseId, moduleId, lessonId) => {
    const response = await fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch lesson content');
    }
    return await response.json();
  };

  // New function to fetch user's progress
  const fetchUserProgress = async () => {
    try {
      const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/progress', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          course_id: courseId,
        },
      });
      if (response.data && response.data.progress) {
        setUserProgress(response.data.progress);
      } else {
        setUserProgress({ module_index: 0, lesson_index: 0 });
      }
    } catch (error) {
      console.error('Error fetching user progress:', error);
      setUserProgress({ module_index: 0, lesson_index: 0 });
    }
  };

  // Modified saveProgress function
  const saveProgress = async (progressPercentage) => {
    if (!currentModule || !lesson || !userProgress) return;

    const currentModuleIndex = currentModule.module_index;
    const currentLessonIndex = lesson.lesson_index;

    // Check if the new progress is ahead of the user's current progress
    const isNewProgress =
      currentModuleIndex > userProgress.module_index ||
      (currentModuleIndex === userProgress.module_index && currentLessonIndex > userProgress.lesson_index);

    if (isNewProgress) {
      try {
        await axios.post(
          'https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/progress',
          {
            course_id: courseId,
            module_id: moduleId,
            lesson_id: lessonId,
            progress_percentage: progressPercentage,
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        console.log('Progress saved successfully');
        // Update userProgress state
        setUserProgress({
          module_index: currentModuleIndex,
          lesson_index: currentLessonIndex,
        });
      } catch (error) {
        console.error('Error saving progress:', error);
      }
    } else {
      console.log('Progress not saved: current lesson is not ahead of user progress');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch modules
        const modulesData = await fetchModules(courseId);
        setModules(modulesData);

        const foundModule = modulesData.find((module) => module.id === parseInt(moduleId));
        if (!foundModule) {
          throw new Error('Module not found');
        }
        setCurrentModule(foundModule);

        // Fetch lessons
        const lessonsData = await fetchLessons(courseId, moduleId);
        setLessons(lessonsData);

        // Fetch user's progress
        await fetchUserProgress();

        if (lessonId) {
          const lessonData = await fetchLessonContent(courseId, moduleId, lessonId);
          setLesson(lessonData);

          // Save initial progress when lesson is loaded
          saveProgress(0);
        } else {
          setLesson(null);
        }

        setLoading(false);
      } catch (err) {
        setError(err.message || 'An unknown error occurred');
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, moduleId, lessonId]);

  const renderLessonContent = () => {
    if (!lesson) return null;

    const commonProps = {
      lesson: lesson,
      onProgressUpdate: saveProgress,
    };

    switch (lesson.type) {
      case 'document':
        return <DocumentLesson {...commonProps} />;
      case 'coding':
        return <CodingLesson {...commonProps} />;
      case 'quiz':
        return <QuizLesson {...commonProps} />;
      case 'web':
        return <WebDevLesson {...commonProps} />;
      default:
        return <p className="text-red-500">Unknown lesson type</p>;
    }
  };

  const navigateToLesson = async (direction) => {
    if (!currentModule || lessons.length === 0) return;

    const sortedLessons = [...lessons].sort((a, b) => a.lesson_index - b.lesson_index);
    const currentLessonIndex = sortedLessons.findIndex((l) => l.id === parseInt(lessonId));
    if (currentLessonIndex === -1) return;

    let newCourseId = courseId;
    let newModuleId = moduleId;
    let newLessonId = null;

    const currentModuleIndex = currentModule.module_index;

    const getNextModule = () => modules.find((m) => m.module_index === currentModuleIndex + 1);
    const getPrevModule = () => modules.find((m) => m.module_index === currentModuleIndex - 1);

    switch (direction) {
      case 'next':
        if (currentLessonIndex + 1 < sortedLessons.length) {
          // Go to next lesson in the sorted array
          newLessonId = sortedLessons[currentLessonIndex + 1].id;
        } else {
          // Try to get the first lesson of the next module
          const nextModule = getNextModule();
          if (nextModule) {
            newModuleId = nextModule.id;
            const newLessons = await fetchLessons(newCourseId, newModuleId);
            if (newLessons.length > 0) {
              const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
              newLessonId = sortedNewLessons[0].id;
              setLessons(sortedNewLessons);
            }
          }
        }
        break;
      case 'previous':
        if (currentLessonIndex > 0) {
          // Go to previous lesson in the sorted array
          newLessonId = sortedLessons[currentLessonIndex - 1].id;
        } else {
          // Try to get the last lesson of the previous module
          const prevModule = getPrevModule();
          if (prevModule) {
            newModuleId = prevModule.id;
            const newLessons = await fetchLessons(newCourseId, newModuleId);
            if (newLessons.length > 0) {
              const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
              newLessonId = sortedNewLessons[sortedNewLessons.length - 1].id;
              setLessons(sortedNewLessons);
            }
          }
        }
        break;
      case 'nextModule':
        const nextModule = getNextModule();
        if (nextModule) {
          newModuleId = nextModule.id;
          const newLessons = await fetchLessons(newCourseId, newModuleId);
          if (newLessons.length > 0) {
            const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
            newLessonId = sortedNewLessons[0].id;
            setLessons(sortedNewLessons);
          }
        }
        break;
      case 'previousModule':
        const prevModule = getPrevModule();
        if (prevModule) {
          newModuleId = prevModule.id;
          const newLessons = await fetchLessons(newCourseId, newModuleId);
          if (newLessons.length > 0) {
            const sortedNewLessons = newLessons.sort((a, b) => a.lesson_index - b.lesson_index);
            newLessonId = sortedNewLessons[0].id;
            setLessons(sortedNewLessons);
          }
        }
        break;
      default:
        break;
    }

    if (newModuleId !== moduleId || newLessonId !== lessonId) {
      try {
        setLoading(true);

        if (newLessonId) {
          const newLessonContent = await fetchLessonContent(newCourseId, newModuleId, newLessonId);
          setLesson(newLessonContent);

          // Update currentModule and lessons if module has changed
          if (newModuleId !== moduleId) {
            const foundModule = modules.find((module) => module.id === parseInt(newModuleId));
            setCurrentModule(foundModule);

            const newLessons = await fetchLessons(newCourseId, newModuleId);
            setLessons(newLessons);
          }

          // Save progress if moving forward
          saveProgress(0);
        } else {
          setLesson(null);
        }

        setLoading(false);

        const url = `/courses/${newCourseId}/modules/${newModuleId}/lessons/${newLessonId}`;
        navigate(url);
      } catch (err) {
        setError(err.message || 'An error occurred while navigating');
        setLoading(false);
      }
    }
  };

  if (loading || !userProgress) {
    return <CodeLoadingAnimation />;
  }

  if (error || !currentModule) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="flex items-center text-xl font-semibold text-red-600 mb-4">
          <AlertCircle className="mr-2" size={24} />
          {error || 'Module not found'}
        </div>
        <p className="text-gray-600 mb-4">
          The requested content could not be found. This might be due to an incorrect URL or the content may have been moved or deleted.
        </p>
        <div className="flex space-x-4">
          <Link
            to="/"
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <Home className="mr-2" size={20} />
            Go to Homepage
          </Link>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-white">
      {renderLessonContent()}
      <LessonNavigation
        currentModule={currentModule}
        modules={modules}
        lessons={lessons}
        currentLessonIndex={lessonId ? lessons.findIndex((l) => l.id === parseInt(lessonId)) : -1}
        courseId={courseId}
        moduleId={moduleId}
        onNavigate={navigateToLesson}
      />
      <ChatBot />
    </div>
  );
};

export default LessonContentPage;
