import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BookOpen, Clock, BarChart2, Circle, ChevronDown, ChevronUp, FileText, ClipboardList, Code } from 'lucide-react'
import Footer from '../Components/Footer'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'

const AllCourses = () => {
  const [courses, setCourses] = useState([])
  const [progress, setProgress] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [expandedDescriptions, setExpandedDescriptions] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with environment variable if preferred
        const BACKEND_BASE_URL = 'https://usitebackend-36ad67a8d6c6.herokuapp.com'

        // Fetch courses data (public)
        const coursesResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/fetch/courses`)
        setCourses(coursesResponse.data)

        // Check for token
        const token = localStorage.getItem('token')

        if (token) {
          // Fetch progress data (authenticated)
          try {
            const headers = { Authorization: `Bearer ${token}` }
            const progressResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/user/progress`, { headers })
            setProgress(progressResponse.data.courses)
          } catch (progressError) {
            console.error('Error fetching progress data:', progressError)
            // Optionally, set a specific error or handle it differently
            // For now, we'll proceed without progress data
          }
        }

        setLoading(false)
      } catch (err) {
        console.error('Error fetching courses:', err)
        setError('Error fetching courses')
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const toggleDescription = (courseId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [courseId]: !prev[courseId]
    }))
  }

  if (loading) {
    return <CodeLoadingAnimation/>;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 text-red-500">
        {error}
      </div>
    )
  }

  return (
    <div className="bg-gray-100 min-h-screen font-mono text-gray-800">
      <CodeForCambodiaHeader />
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-center">
          <span className="text-blue-600">const</span>{' '}
          <span className="text-green-600 Nokora">ថ្នាក់ទាំងអស់</span>{' '}
          <span className="text-blue-600">=</span>{' '}
          <span className="text-orange-500">{'['}</span>
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map((course) => {
            // Find progress for the current course
            const courseProgress = progress.find(p => p.course_id === course.id)
            const isExpanded = expandedDescriptions[course.id]

            return (
              <div key={course.id} className="bg-white rounded-lg overflow-hidden border border-gray-200 transition-all duration-300 hover:border-blue-500">
                <div className="bg-gray-200 px-4 py-2 flex items-center">
                  <Circle size={12} fill="#ff5f56" stroke="none" className="mr-2" />
                  <Circle size={12} fill="#ffbd2e" stroke="none" className="mr-2" />
                  <Circle size={12} fill="#27c93f" stroke="none" className="mr-2" />
                  <span className="text-sm text-gray-600 flex-grow text-center Nokora">
                    {course.status === 0 ? 'មកដល់ឆាប់ៗ' : 'Free course'}
                  </span>
                </div>
                <div className="p-6">
                  <h2 className="text-xl font-bold mb-2 text-blue-600">{course.course_name}</h2>
                  <div className={`text-gray-600 mb-2 Nokora ${isExpanded ? '' : 'line-clamp-3'}`}>
                    {course.description}
                  </div>
                  <button 
                    onClick={() => toggleDescription(course.id)}
                    className="text-blue-500 Nokora hover:text-blue-700 text-sm flex items-center mb-4"
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUp size={16} className="mr-1" />
                        បន្ថយ
                      </>
                    ) : (
                      <>
                        <ChevronDown size={16} className="mr-1" />
                        អានបន្ថែម
                      </>
                    )}
                  </button>
                  <div className="grid grid-cols-2 gap-2 text-sm text-gray-500 mb-4 Nokora">
                    <span className="flex items-center">
                      <BookOpen className="w-4 h-4 mr-1" />
                      {course.module_count} ជំពូក
                    </span>
                    <span className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      {course.lesson_count} មេរៀន
                    </span>
                    <span className="flex items-center">
                      <ClipboardList className="w-4 h-4 mr-1" />
                      {course.quiz_count} ប្រលង
                    </span>
                    <span className="flex items-center">
                      <FileText className="w-4 h-4 mr-1" />
                      {course.document_count} ឯកសារ
                    </span>
                    <span className="flex items-center col-span-2">
                      <Code className="w-4 h-4 mr-1" />
                      {course.coding_count} លំហាត់កូដ
                    </span>
                  </div>

                  {/* Progress Section */}
                  {courseProgress ? (
                    <div className="mb-4">
                      <div className="flex items-center justify-between text-sm text-gray-500 mb-1">
                        <span className="flex items-center Nokora">
                          <BarChart2 className="w-4 h-4 mr-1" />
                          វឌ្ឍនភាពការរៀន: {courseProgress.progress_percentage}%
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className="bg-blue-600 h-2 rounded-full" 
                          style={{width: `${courseProgress.progress_percentage}%`}}
                        ></div>
                      </div>
                      <p className="text-xs text-gray-500 Nokora mt-1">
                        កំពុងរៀន: {courseProgress.current_lesson_name}
                      </p>
                    </div>
                  ) : (
                    <div className="mb-4">
                      <p className="text-xs text-gray-500 Nokora">No progress data available.</p>
                    </div>
                  )}

                  {/* Action Button */}
                  {course.status === 1 ? (
                    <Link 
                      to={`/modules/${course.id}/course`}
                      className={`block Nokora w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded text-center transition-colors duration-300 ${!courseProgress && 'bg-green-500 hover:bg-green-600'}`}
                    >
                      {courseProgress ? 'រៀនបន្តរ' : 'រៀនឥឡូវ'}
                    </Link>
                  ) : (
                    <button 
                      className="block w-full Nokora bg-gray-300 text-gray-500 font-medium py-2 px-4 rounded text-center cursor-not-allowed"
                      disabled
                    >
                      មកដល់ឆាប់ៗ
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        <div className="text-center mt-8 text-2xl">
          <span className="text-orange-500">{']'}</span>
          <span className="text-gray-800">;</span>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default AllCourses