import React, { useState } from 'react';
import { Code } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = ({onRegisterSuccess}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!firstName || !lastName || !age || !gender || !email || !password) {
      setError('All fields are required');
      setIsLoading(false);
      return;
    }

    if (isNaN(parseInt(age))) {
      setError('Age must be a number');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/register', {
        first_name: firstName,
        last_name: lastName,
        age: parseInt(age),
        gender,
        email,
        password
      });

      if (response.data.token) {
        onRegisterSuccess(response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'An error occurred during registration');
      } else {
        console.log(error);
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-white text-gray-800 font-mono">
      <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="w-full max-w-md mx-auto lg:max-w-lg">
          <div className="text-left mb-8">
            <Link to="/" className="text-3xl font-extrabold text-gray-900 block hover:text-blue-600 transition-colors">
              <span className="text-orange-500">const</span> <span className="text-blue-600">CodeForCambodia</span> <span className="text-orange-500">=</span> {'{'}
            </Link>
          </div>

          <div className="bg-gray-50 p-8 rounded-lg border border-gray-200 shadow-sm">
            <div className="mb-6 text-sm text-gray-500">
              <span className="text-orange-500">function</span> <span className="text-blue-600">register</span>() {'{'}
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> firstName <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> lastName <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="age" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> age <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="age"
                    name="age"
                    type="number"
                    required
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> gender <span className="text-orange-500">=</span>
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    required
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> email <span className="text-orange-500">=</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> password <span className="text-orange-500">=</span>
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> confirmPassword <span className="text-orange-500">=</span>
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {error && (
                <div className="text-red-600 text-sm">
                  <span className="text-orange-500">console</span>.error("{error}");
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-yellow-300 disabled:opacity-50"
                >
                  {isLoading ? 'Processing...' : 'createAccount()'};
                </button>
              </div>
            </form>
            <div className="mt-6 text-sm text-gray-500">{'}'}</div>
          </div>

          <div className="mt-8 text-center">
            <Link to="/login" className="text-blue-600 hover:text-blue-500">
              <span className="text-orange-500 Nokora">មានគណនីហើយ?</span><br></br><span className="text-blue-600">login</span>() {'{'}...{'}'}
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <div className="absolute inset-0 h-full w-full">
          <div className="h-full w-full bg-gray-100 p-12 flex flex-col justify-center border-l border-gray-200 ">
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចូលគណនី</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "ដេីម្បីរៀន សុំចូលគណនី";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">រៀន FREE</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "រៀនថ្នាក់ Python, C, HTML & CSS ដើយមិនគិតថ្លៃ";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចុះឈ្មោះ FREE</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "បង្កើតគណនីមិនគិតថ្លៃ";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="mt-8 space-y-2 font-nokora Nokora">
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> ជំនាញ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">buildSkills</span>();
              </div>
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> បទពិសោធន៍ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">gainExperience</span>();
              </div>
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> ចាប់ផ្តើម <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">startLearning</span>();
              </div>
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2 Nokora">
                <span className="text-blue-600">console</span>.log(ជំនាញ, បទពិសោធន៍, ចាប់ផ្តើម);
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;