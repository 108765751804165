import React, { useState } from 'react';
import { BookOpen, Award, BarChart, Code, ChevronDown, ChevronUp, Layers, FileText, ClipboardList, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function CourseCard({ course }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLessonTypesExpanded, setIsLessonTypesExpanded] = useState(false);

  const truncateDescription = (description, lines = 2) => {
    const words = description.split(' ');
    const truncated = words.slice(0, lines * 4).join(' ');
    return truncated + (words.length > lines * 4 ? '...' : '');
  };

  const toggleDescription = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const toggleLessonTypes = (e) => {
    e.preventDefault();
    setIsLessonTypesExpanded(!isLessonTypesExpanded);
  };

  return (
    <Link 
      to={`/modules/${course.id}/course`}
      className="block w-full font-mono bg-gradient-to-br from-yellow-50 to-yellow-100 text-gray-800 p-6 rounded-lg transition-all duration-300 overflow-hidden border-2 border-yellow-300 group custom-hover-shadow"
    >
      <div className="border-b border-yellow-300 pb-2 mb-4 opacity-80 group-hover:opacity-100 transition-opacity duration-300">
        <Code className="inline-block mr-2 text-blue-600" size={16} />
        <span className="text-xs text-blue-700">from skill_paths import Course</span>
      </div>
      
      <div className="mb-4 opacity-90">
        <span className="text-purple-600">class</span>{' '}
        <span className="text-blue-700 group-hover:text-blue-800 transition-colors duration-300">ថ្នាក់</span>:
      </div>
      
      <div className="pl-4">
        <div className="mb-4">
          <span className="text-blue-600 opacity-90">name</span>{' '}
          <span className="text-gray-700 opacity-90">=</span>{' '}
          <span className="text-2xl font-bold text-blue-800 group-hover:text-blue-900 transition-colors duration-300">"{course.course_name}"</span>
        </div>
        <div className="mb-2 opacity-90">
          <span className="text-blue-600">description</span>{' '}
          <span className="text-gray-700">=</span>{' '}
          <span className="text-green-700 Nokora  group-hover:text-green-800 transition-colors duration-300">
            {isExpanded ? course.description : truncateDescription(course.description)}
          </span>
          {course.description.split(' ').length > 20 && (
            <button 
              onClick={toggleDescription}
              className="Nokora block text-orange-600 hover:text-orange-800 mt-2 flex items-center"
              aria-expanded={isExpanded}
            >
              {isExpanded ? (
                <>
                  បន្ថយ <ChevronUp size={16} className="ml-1" />
                </>
              ) : (
                <>
                  អានបន្ថែម <ChevronDown size={16} className="ml-1" />
                </>
              )}
            </button>
          )}
        </div>
        <div className="mb-2 opacity-90">
          <span className="text-blue-600">details</span>{' '}
          <span className="text-gray-700">=</span>{' '}
          <span className="text-orange-600">{'{'}</span>
        </div>
        <div className="pl-4 opacity-90">
          <div className="flex items-center mb-1 group-hover:translate-x-2 transition-transform duration-300">
            <Layers className="w-4 h-4 mr-2 text-blue-600" />
            <span className="text-blue-600 Nokora">"ជំពូក"</span>:{' '}
            <span className="text-orange-600 ml-1">{course.module_count}</span>,
          </div>
          <div className="flex items-center mb-1 group-hover:translate-x-2 transition-transform duration-300">
            <FileText className="w-4 h-4 mr-2 text-blue-600" />
            <span className="text-blue-600 Nokora">"មេរៀន"</span>:{' '}
            <span className="text-orange-600 ml-1">{course.lesson_count}</span>,
          </div>
          <div className="flex items-center mb-2 Nokora">
            <Clock className="w-4 h-4 mr-2 text-blue-600 " />
            <span className="text-blue-600">"ប្រភេទ_មេរៀន"</span>:{' '}
            <button
              onClick={toggleLessonTypes}
              className="ml-2 px-2 py-1 Nokora bg-gray-100 text-gray-700 text-sm rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:border-blue-500 transition-colors duration-200 flex items-center"
              aria-expanded={isLessonTypesExpanded}
            >
              {isLessonTypesExpanded ? '-' : '+'} {isLessonTypesExpanded ? 'បន្ថយ()' : 'បង្ហាញ()'}
            </button>
          </div>
          <div 
            className={`pl-4 overflow-hidden transition-all duration-300 Nokora ease-in-out ${
              isLessonTypesExpanded ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <div className="flex items-center mb-1 group-hover:translate-x-2 transition-transform duration-300">
              <ClipboardList className="w-4 h-4 mr-2 text-purple-500" />
              <span className="text-blue-600">"ប្រលង"</span>:{' '}
              <span className="text-orange-600 ml-1">{course.quiz_count}</span>,
            </div>
            <div className="flex items-center mb-1 group-hover:translate-x-2 transition-transform duration-300">
              <FileText className="w-4 h-4 mr-2 text-red-500" />
              <span className="text-blue-600">"ឯកសារ"</span>:{' '}
              <span className="text-orange-600 ml-1">{course.document_count}</span>,
            </div>
            <div className="flex items-center group-hover:translate-x-2 transition-transform duration-300">
              <Code className="w-4 h-4 mr-2 text-indigo-500" />
              <span className="text-blue-600">"លំហាត់កូដ"</span>:{' '}
              <span className="text-orange-600 ml-1">{course.coding_count}</span>
            </div>
          </div>
        </div>
        <div className="mt-1 opacity-90">
          <span className="text-orange-600">{'}'}</span>
        </div>
      </div>
      
      <div className="border-t border-yellow-300 pt-2 mt-4 opacity-80 group-hover:opacity-100 transition-opacity duration-300">
        <span className="text-xs text-blue-700">course = Course()</span>
      </div>

      <style jsx>{`
        .custom-hover-shadow {
          transition: all 0.3s ease;
        }
        .custom-hover-shadow:hover {
          box-shadow: 8px 8px 0px 0px rgba(0,0,0,0.2);
          transform: translate(-4px, -4px);
        }
      `}</style>
    </Link>
  );
}