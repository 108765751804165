import React, { useState } from 'react';
import { Code, Facebook, Github, Apple, Eye, EyeOff } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ForgotPassword from '../../Components/Modals/ForgotPassword';

const Login = ({onLoginSuccess}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/login', { email, password });
      if (response.status === 200 && response.data.token) {
        onLoginSuccess(response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'An error occurred during login');
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex min-h-screen bg-white text-gray-800 font-mono">
      <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-left">
            <Link to="/" className="mt-6 text-3xl font-extrabold text-gray-900 block hover:text-blue-600 transition-colors">
              <span className="text-orange-600">const</span> <span className="text-blue-600">CodeForCambodia</span> <span className="text-orange-600">=</span> {'{'}
            </Link>
          </div>

          <div className="mt-3 bg-gray-100 p-6 rounded-lg border border-gray-300 shadow-sm">
            <div className="mb-4 text-sm text-gray-500">
              <span className="text-orange-600">function</span> <span className="text-blue-600">login</span>() {'{'}
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  <span className="text-purple-600">let</span> email <span className="text-purple-600">=</span>
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  <span className="text-purple-600">let</span> password <span className="text-purple-600">=</span>
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm pr-10"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
                  </button>
                </div>
              </div>

              {error && (
                <div className="text-red-600 text-sm">
                  <span className="text-orange-600">console</span>.error("{error}");
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full text-yellow-300 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {isLoading ? 'Processing...' : 'signIn()'}
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-gray-500">{'}'}</div>
          </div>

          <div className="mt-4 text-center">
            <Link to="/register" className="text-blue-600 hover:text-blue-800">
              <span className="text-orange-600 Nokora">អត់មានគណនី?</span><br></br> <span className="text-blue-600">createAccount</span>() {'{'}...{'}'}
            </Link>
          </div>
          <div className="mt-4 text-center">
            <button
              onClick={() => setIsForgotPasswordOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Code className="mr-2 h-4 w-4" />
              <span className="text-orange-600">function</span> <span className="text-red-600">forgotPassword</span>() {'{'}...{'}'}
            </button>
          </div>
        </div>
        <div className="text-left">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {'}'}
          </h2>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <div className="absolute inset-0 h-full w-full object-cover">
          <div className="h-full w-full bg-gray-100 p-12 flex flex-col justify-center border-l border-gray-200 ">
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចូលគណនី</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "ដេីម្បីរៀន សុំចូលគណនី";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">រៀន FREE</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "រៀនថ្នាក់ Python, C, HTML & CSS ដើយមិនគិតថ្លៃ";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="text-gray-800 text-xl mb-4">
              <Code className="inline-block mr-2 text-blue-600" /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចុះឈ្មោះ FREE</span>() {'{'}
            </div>
            <div className="text-green-600 text-xl ml-4 mb-2 Nokora">
              <span className="text-orange-600">return</span> "បង្កើតគណនីមិនគិតថ្លៃ";
            </div>
            <div className="text-gray-800 text-xl mb-4">
              {'}'}
            </div>
            <div className="mt-8 space-y-2 font-nokora">
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> ជំនាញ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">buildSkills</span>();
              </div>
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> បទពិសោធន៍ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">gainExperience</span>();
              </div>
              <div className="h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-orange-600 mr-1">const</span> ចាប់ផ្តើម <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">startLearning</span>();
              </div>
              <div className="Nokora h-8 bg-white border border-gray-300 rounded flex items-center px-2">
                <span className="text-blue-600">console</span>.log(ជំនាញ, បទពិសោធន៍, ចាប់ផ្តើម);
              </div>
            </div>
          </div>
        </div>
      </div>
      {isForgotPasswordOpen && <ForgotPassword onClose={() => setIsForgotPasswordOpen(false)} />}
      <style jsx global>{`
        @font-face {
          font-family: 'Nokora';
          src: url('/fonts/Nokora-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Nokora';
          src: url('/fonts/Nokora-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
        }
        .font-nokora {
          font-family: 'Nokora', sans-serif;
        }
      `}</style>
    </div>
  );
};

export default Login;