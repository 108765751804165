import { Flame, Trophy, Star } from 'lucide-react'


export default function StreakComponent({ currentStreak, longestStreak }) {
  return (
    <div className="mt-4">
      <h2 className="text-xl mb-2 text-blue-600">
        <span className="text-green-600">streak:</span> <span className="text-blue-600">{'{'}</span>
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ml-4">
        <div className="bg-gray-100 rounded-lg p-4">
          <p className="text-lg font-semibold mb-2 flex items-center">
            <Flame className="mr-2 text-orange-400" /> 
            <span className="text-green-600">currentStreak:</span>
          </p>
          <p className="text-3xl font-bold text-blue-600">{currentStreak}<span className="text-sm text-gray-600"> // days</span></p>
        </div>
        <div className="bg-gray-100 rounded-lg p-4">
          <p className="text-lg font-semibold mb-2 flex items-center">
            <Trophy className="mr-2 text-yellow-400" /> 
            <span className="text-green-600">longestStreak:</span>
          </p>
          <p className="text-3xl font-bold text-blue-600">{longestStreak}<span className="text-sm text-gray-600"> // days</span></p>
        </div>
      </div>
      <div className="mt-4 text-center">
        <p className="text-lg font-semibold flex items-center justify-center text-gray-600">
          <Star className="mr-2 text-yellow-400" />
          <span className="text-green-600">status:</span> 
          <span className="text-orange-500 ml-2">
            "{currentStreak >= longestStreak ? "New record! 🔥" : `${longestStreak - currentStreak} days to beat your record!`}"
          </span>
        </p>
      </div>
      <span className="text-blue-600 text-xl font-bold mt-2 block">{'}'}</span>
    </div>
  )
}