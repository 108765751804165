import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Code, BookOpen, ArrowRight, X, ChevronDown, ChevronUp } from 'lucide-react';

export default function ViewAllCoursesModal({ allCourses, isOpen, onClose }) {
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const toggleDescription = (courseId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [courseId]: !prev[courseId]
    }));
  };

  const truncateDescription = (description, lines = 3) => {
    const words = description.split(' ');
    const truncated = words.slice(0, lines * 10).join(' ');
    return truncated + (words.length > lines * 10 ? '...' : '');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div ref={modalRef} className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="bg-blue-600 text-white p-4 flex justify-between items-center">
          <h2 className="text-2xl font-bold">
            <Code className="inline-block mr-2" />
            <span className="text-yellow-300">const</span> <span className="text-white">allCourses</span> <span className="text-yellow-300">=</span> <span className="text-white">[</span>
          </h2>
          <button 
            onClick={onClose} 
            className="text-white hover:text-gray-200 transition-colors"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
        </div>

        <div className="overflow-y-auto flex-grow p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {allCourses.map((course, index) => (
              <div key={course.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-md ">
                <div className="bg-blue-500 text-white p-3">
                  <h3 className="text-lg font-semibold">
                    <BookOpen className="inline mr-2" />
                    <span className="text-yellow-300">course</span> <span className="text-white">{index + 1}</span>
                  </h3>
                </div>
                <div className="p-4">
                  <h4 className="font-semibold text-lg mb-2 lg:text-xl">{course.course_name}</h4>
                  <div className="text-base text-gray-600 mb-4 lg:text-lg Nokora">
                    <p>{expandedDescriptions[course.id] ? course.description : truncateDescription(course.description)}</p>
                    {course.description.split(' ').length > 30 && (
                      <button 
                        onClick={() => toggleDescription(course.id)}
                        className="text-blue-600 Nokora hover:text-blue-800 mt-2 flex items-center"
                        aria-expanded={expandedDescriptions[course.id]}
                        aria-controls={`description-${course.id}`}
                      >
                        {expandedDescriptions[course.id] ? (
                          <>
                            បន្ថយ <ChevronUp size={16} className="ml-1" />
                          </>
                        ) : (
                          <>
                            អានបន្ថែម <ChevronDown size={16} className="ml-1" />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                  {course.status === 1 ? (
                  <Link 
                    to={`/modules/${course.id}/course`}
                    className="inline-flex items-center justify-center w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                    onClick={onClose}
                  >
                    <ArrowRight className="mr-2" size={16} /> View Course
                  </Link>
                  ) : (
                    <div className="text-gray-500">
                      <span className="text-red-500">Coming Soon</span>
                    </div>
                  )}
                </div>
                <div className="bg-gray-200 p-2 text-sm text-gray-500">
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-blue-600 text-white p-4 text-right">
          <span className="text-2xl font-bold">];</span>
        </div>
      </div>
    </div>
  );
}