import React, { useState, useEffect } from 'react'

const codeSnippets = [
  'const loading = true;',
  'while (loading) {',
  '  console.log("Still loading...");',
  '  await sleep(1000);',
  '}',
  'console.log("Done!");',
]

export default function CodeLoadingAnimation() {
  const [currentLine, setCurrentLine] = useState(0)
  const [currentChar, setCurrentChar] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentChar < codeSnippets[currentLine].length) {
        setCurrentChar(currentChar + 1)
      } else if (currentLine < codeSnippets.length - 1) {
        setCurrentLine(currentLine + 1)
        setCurrentChar(0)
      } else {
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [currentLine, currentChar])

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 p-4">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200">
        <div className="flex items-center justify-start px-4 py-2 bg-gray-100 border-b border-gray-200">
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-400 mr-2"></div>
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-400 mr-2"></div>
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-400"></div>
        </div>
        <div className="p-2 sm:p-4 font-mono text-xs sm:text-sm">
          {codeSnippets.map((line, index) => (
            <div key={index} className="mb-1">
              <span className="text-blue-600">{'>'}</span>{' '}
              <span className="text-gray-800">
                {index < currentLine
                  ? line
                  : index === currentLine
                  ? line.slice(0, currentChar)
                  : ''}
              </span>
              {index === currentLine && (
                <span className="animate-pulse text-gray-400">|</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}