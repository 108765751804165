import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Book, CheckCircle, AlertCircle, Lock, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const LessonCard = ({ lesson, courseId, moduleId, isLocked, onContinueCourse, userProgress }) => {
  const [quizResult, setQuizResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchLessonDetails = async () => {
    if (!isLocked) {
      try {
        const response = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        return response.data;
      } catch (err) {
        console.error('Error fetching lesson details:', err);
        setError('Failed to fetch lesson details');
      }
    }
  };

  useEffect(() => {
    const fetchQuizResult = async () => {
      if (lesson.type === 'quiz' && !isLocked) {
        setLoading(true);
        const content = await fetchLessonDetails();
        console.log(content);

        try {
          const response = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/quiz-results/${content.id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          setQuizResult(response.data);
        } catch (err) {
          setError('អ្នកអត់ទាន់ប្រលងទេ');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchQuizResult();
  }, [lesson.id, lesson.type, isLocked, courseId, moduleId]);

  const getCardStyle = (percentage) => {
    if (percentage >= 80) return 'border-green-500 bg-green-50';
    if (percentage >= 60) return 'border-yellow-500 bg-yellow-50';
    return 'border-red-500 bg-red-50';
  };

  const getTextStyle = (percentage) => {
    if (percentage >= 80) return 'text-green-700';
    if (percentage >= 60) return 'text-yellow-700';
    return 'text-red-700';
  };

  const handleClick = (e) => {
    if (isLocked) {
      e.preventDefault();
      setShowModal(true);
    }
  };

  const Modal = () => (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 Nokora"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            initial={{ scale: 0.9, y: 50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.9, y: 50 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="bg-white rounded-lg shadow-xl w-full max-w-sm overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-gray-100 px-4 py-2 flex justify-between items-center">
              <div className="flex space-x-2">
                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              </div>
              <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">
                <X size={18} />
              </button>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold mb-4">មេរៀនទាន់រៀនដល់ទេ</h3>
              <p className="text-gray-700 mb-6">
                សុំរៀនតាមលំដាប់លំដោយ អត់អាចរៀនរំលងបានទេ!
              </p>
              <div className="flex flex-col space-y-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-200 w-full"
                >
                  បិទ
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setShowModal(false);
                    onContinueCourse();
                  }}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 w-full"
                >
                  {userProgress ? 'រៀនបន្តរ' : 'ចាប់ផ្តើមរៀន'}
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <>
      <Link to={isLocked ? '#' : `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`} className="block" onClick={handleClick}>
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          className={`border-2 rounded-lg p-4 mb-4 transition-all duration-300 hover:shadow-lg ${
            lesson.type === 'quiz' && quizResult 
              ? getCardStyle(quizResult.percentage)
              : 'border-gray-200 hover:border-blue-500'
          }`}
        >
          <div className="flex flex-col sm:flex-row justify-between items-start mb-2">
            <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">{lesson.lesson_name}</h3>
            {lesson.type === 'quiz' ? (
              <div className="flex items-center">
                <AlertCircle size={20} className="text-blue-500 mr-1" />
                <span className="text-sm font-medium text-blue-500 Nokora">ប្រលង</span>
              </div>
            ) : (
              <div className="flex items-center">
                <Book size={20} className="text-gray-500 mr-1" />
                <span className="text-sm font-medium text-gray-500 Nokora">{lesson.type === 'document' ? 'ឯកសារ' : 'លំហាត់កូដ'}</span>
              </div>
            )}
          </div>
          <p className="text-gray-600 mb-2 text-sm sm:text-base">{lesson.description}</p>
          {lesson.type === 'quiz' && (
            <div className="mt-2">
              {loading && <p className="text-sm text-gray-500">Loading quiz result...</p>}
              {error && <p className="text-sm text-red-500 Nokora">{error}</p>}
              {quizResult && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className={`flex items-center ${getTextStyle(quizResult.percentage)}`}
                >
                  <CheckCircle size={16} className="mr-1" />
                  <span className="text-sm font-medium">
                    Score: {quizResult.percentage}%
                  </span>
                </motion.div>
              )}
            </div>
          )}
          {isLocked && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-2 flex items-center text-gray-500"
            >
              <Lock size={16} className="mr-1" />
              <span className="text-sm">Locked</span>
            </motion.div>
          )}
        </motion.div>
      </Link>
      <Modal />
    </>
  );
};

export default LessonCard;