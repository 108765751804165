import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Menu, ChevronLeft, ChevronRight } from 'lucide-react';

export default function LessonNavigation({
  currentModule,
  modules,
  lessons,
  currentLessonIndex,
  courseId,
  moduleId,
  onNavigate
}) {
  const isFirstLesson = currentLessonIndex === 0;
  const isLastLesson = currentLessonIndex === lessons.length - 1;
  const currentModuleIndex = modules.findIndex(m => m.id === parseInt(moduleId));
  const isFirstModule = currentModuleIndex === 0;
  const isLastModule = currentModuleIndex === modules.length - 1;

  useEffect(() => {
    console.log(currentModule);
    console.log(modules);
    console.log(lessons);
  }, []);

  const getLessonsForModule = (moduleId) => {
    return lessons.filter(lesson => lesson.module_id === moduleId);
  };
  
  const handleNextNavigation = () => {
    if (isLastLesson) {
      if (!isLastModule) {
        onNavigate('nextModule');
      }
    } else {
      onNavigate('next');
    }
  };
  
  const handlePreviousNavigation = () => {
    if (isFirstLesson) {
      if (!isFirstModule) {
        const prevModule = modules[currentModuleIndex - 1];
        onNavigate('previousModule', { moduleId: prevModule.id, lessonIndex: -1 });
      }
    } else {
      onNavigate('previous', { lessonIndex: currentLessonIndex - 1 });
    }
  };

  return (
    <div className="bottom-0 left-0 right-0 bg-gray-50 border-t border-gray-200 py-2 md:fixed">
      <div className="w-full px-4">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <Link 
              to={`/modules/${courseId}/course`}
              className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              <Menu size={20} />
            </Link>
            <div className="flex items-center space-x-1">
              <button
                onClick={() => onNavigate('previousModule', { moduleId: modules[currentModuleIndex - 1]?.id, lessonIndex: -1 })}
                className={`p-1 rounded transition-colors duration-200 ${
                  isFirstModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
                }`}
                disabled={isFirstModule}
              >
                <ChevronLeft size={20} />
              </button>
              <span className="text-sm font-medium text-gray-700">{currentModule.module_name}</span>
              <button
                onClick={() => onNavigate('nextModule', { moduleId: modules[currentModuleIndex + 1]?.id, lessonIndex: 0 })}
                className={`p-1 rounded transition-colors duration-200 ${
                  isLastModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
                }`}
                disabled={isLastModule}
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePreviousNavigation}
              className={`flex items-center px-2 py-1 rounded transition-colors duration-200 ${
                isFirstLesson && isFirstModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
              }`}
              disabled={isFirstLesson && isFirstModule}
            >
              <ArrowLeft size={16} className="mr-1 hidden sm:inline" />
              <span className="text-sm">Previous</span>
            </button>
            <div className="text-sm text-gray-600 hidden sm:block">
              {currentLessonIndex + 1} / {lessons.length}
            </div>
            <button
              onClick={handleNextNavigation}
              className={`flex items-center px-2 py-1 rounded transition-colors duration-200 ${
                isLastLesson && isLastModule ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200'
              }`}
              disabled={isLastLesson && isLastModule}
            >
              <span className="text-sm">Next</span>
              <ArrowRight size={16} className="ml-1 hidden sm:inline" />
            </button>
          </div>
        </div>
        
        <div className="mt-2 w-full bg-gray-200 h-1">
          <div 
            className="bg-blue-500 h-1" 
            style={{ width: `${((currentLessonIndex + 1) / lessons.length) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}
