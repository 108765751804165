import React, { useState, useEffect } from 'react'; import { ArrowRight, Code, Users } from 'lucide-react'; import { useNavigate } from 'react-router-dom'; import axios from 'axios'; import { motion } from 'framer-motion';

const TypeWriter = ({ text, className }) => { const [displayText, setDisplayText] = useState(''); const [index, setIndex] = useState(0);

useEffect(() => { if (index < text.length) { const timer = setTimeout(() => { setDisplayText((prev) => prev + text[index]); setIndex((prev) => prev + 1); }, 50); return () => clearTimeout(timer); } }, [index, text]);

return ( <span className={className}> {displayText} <motion.span animate={{ opacity: [0, 1, 0] }} transition={{ repeat: Infinity, duration: 1.5 }} className="inline-block w-1 h-5 bg-gray-600 ml-2" /> </span> ); };

const Hero = () => { const navigate = useNavigate(); const [totalUsers, setTotalUsers] = useState(null);

useEffect(() => { const fetchTotalUsers = async () => { try { const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/leaderboard/total-users'); setTotalUsers(response.data[0].total_users); } catch (error) { console.error('Error fetching total users:', error); } };

fetchTotalUsers();
}, []);

return ( <div className="bg-white border-b border-gray-200 font-mono"> <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24 relative"> {/* Line numbers */} <div className="absolute left-0 top-0 bottom-0 w-12 hidden sm:flex flex-col items-end pr-4 text-gray-400 text-xs border-r border-gray-200"> {Array.from({ length: 15 }, (_, i) => ( <div key={i} className="leading-6">{i + 1}</div> ))} </div>

    <div className="sm:pl-16">
      {/* Import statement */}
      <div className="text-gray-500 mb-6 text-sm sm:text-base opacity-50">
        <Code className="inline-block mr-2" size={16} />
        <span className="text-purple-600">import</span>{' '}
        <span className="text-blue-600">{'{ Hero }'}</span>{' '}
        <span className="text-purple-600">from</span>{' '}
        <span className="text-orange-500">'@/components'</span>;
      </div>

      {/* Hero content */}
      <div className="text-center">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-gray-800 text-balance">

          <TypeWriter 
            text='"CodeForCambodia"'
            className="text-orange-500 text-2xl sm:text-4xl"
          />
          ;
        </h1>
        <p className=" mb-8 text-gray-600">
          <TypeWriter 
            text='"នរណាក៏អាចកូដបានដែរ"'
            className="text-purple-500 Nokora text-base sm:text-lg md:text-2xl"
          />
          ;
        </p>

        <motion.button 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          onClick={() => navigate('/login')} 
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md text-sm sm:text-lg font-medium transition duration-300 ease-in-out inline-flex items-center"
        >
          <span className="text-white">startLearning</span>
          <span className="text-yellow-300">{'()'}</span>
        </motion.button>

        {totalUsers !== null && (
          <div className="mt-6 text-gray-600 flex items-center justify-center">
            <Users className="inline-block mr-2" size={16} />
            <span className="text-green-600 ml-2 Nokora">អ្នកប្រើប្រាស់សរុប</span>{' '}
            <span className="text-blue-600 mx-1">=</span>{' '}
            <TypeWriter 
              text={totalUsers.toString()}
              className="text-orange-500"
            />
            ;
          </div>
        )}
      </div>

      {/* Export statement */}
      <div className="text-gray-500 mt-6 text-sm sm:text-base opacity-50">
        <span className="text-purple-600">export default</span>{' '}
        <span className="text-blue-600">Hero</span>;
      </div>
    </div>

    {/* Animated code particles */}
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {Array.from({ length: 30 }).map((_, index) => (
        <motion.div
          key={index}
          className="absolute text-gray-300 text-opacity-20 text-xs"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            opacity: 0,
          }}
          animate={{
            y: [null, Math.random() * window.innerHeight],
            x: [null, Math.random() * window.innerWidth],
            opacity: [0, 1, 0],
            rotate: [0, 360],
          }}
          transition={{
            duration: Math.random() * 20 + 10,
            repeat: Infinity,
            repeatType: 'loop',
          }}
        >
          {['const', 'let', 'var', 'function', 'if', 'for', 'while', 'return', '{}', '[]', '()'][Math.floor(Math.random() * 11)]}
        </motion.div>
      ))}
    </div>
  </div>
</div>
); };

export default Hero;