import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import { FileText, Code } from 'lucide-react'

// Assuming these components are available in your project
import FreeTrialHeader from '../../Components/Headers/FreeTrialHeader'
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader'
import options from '../../Components/Lessons/OptionsQuill'

export default function DocumentLesson({ lesson }) {
  

  return (
    <div className="flex flex-col text-gray-800 font-mono md:h-screen md:pb-20">
      <CodeForCambodiaHeader />

      <main className="flex-grow overflow-y-auto px-2 sm:px-4">
        <div className="mx-auto w-full h-full py-4 sm:py-8 relative rounded-lg">
          <div className="p-2 sm:p-6">
            {/* Line number decoration */}
            <div className="absolute left-0 top-0 bottom-0 w-6 sm:w-12 flex flex-col items-end pr-1 sm:pr-2 text-gray-400 text-xs sm:text-sm">
              {Array.from({ length: 30 }, (_, i) => (
                <div key={i} className="leading-5 sm:leading-6">{i + 1}</div>
              ))}
            </div>

            {/* Top decoration */}
            <div className="flex items-center mb-4 sm:mb-6 pb-2 sm:pb-4 pl-8 sm:pl-16 text-xs sm:text-sm">
              <Code className="text-green-600 mr-1 sm:mr-3" size={16} />
              <span className="text-green-600 mr-1 sm:mr-2">import</span>
              <span className="text-blue-600">Lesson</span>
              <span className="text-green-600 mx-1 sm:mx-2">from</span>
              <span className="text-orange-500">'{lesson?.lesson_name || 'Document'}'</span>
            </div>

            {/* Lesson title */}
            <div className="flex items-center mb-4 sm:mb-6 pl-8 sm:pl-16">
              <FileText className="text-blue-600 mr-2 sm:mr-3" size={20} />
              <h2 className="text-xl sm:text-2xl font-bold text-blue-600">
                {lesson?.lesson_name || 'Document Lesson'}
              </h2>
            </div>

            {/* Lesson content */}
            {lesson?.content ? (
              <article className="space-y-4 pl-8 sm:pl-16 pr-2 sm:pr-4">
                <div className="prose prose-sm sm:prose lg:prose-lg max-w-none Nokora">
                  {parse(lesson.content, options)}
                </div>
              </article>
            ) : (
              <div className="flex items-center justify-center h-32 sm:h-64 bg-gray-100 rounded ml-8 sm:ml-16 mr-2 sm:mr-4">
                <p className="text-gray-600 italic text-sm sm:text-base">
                  No content available for this lesson.
                </p>
              </div>
            )}

            {/* Bottom decoration */}
            <div className="mt-4 sm:mt-8 pl-8 sm:pl-16 border-t border-gray-200 pt-2 sm:pt-4 text-xs sm:text-sm">
              <span className="text-green-600">export default</span>
              <span className="text-blue-600 ml-1 sm:ml-2">Lesson</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}