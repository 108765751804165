import React, { useState } from 'react'
import { Code, Menu, X } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function CodeForCambodiaHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <header className="bg-white text-gray-800 font-mono border-b border-gray-300">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center space-x-2">
            <Code className="h-8 w-8 text-blue-500" />
            <span className="text-lg sm:text-xl font-bold">
              <span className="text-orange-500">const</span>{' '}
              <span className="text-blue-500">CodeForCambodia</span>
              <span className="text-orange-500">;</span>
            </span>
          </Link>
          
          <div className="hidden sm:flex items-center space-x-4">

            <Link to="/courses" className="text-blue-500 hover:text-blue-700 transition-colors duration-200">
              Courses
            </Link>
            <Link to="/leaderboard" className="text-blue-500 hover:text-blue-700 transition-colors duration-200">
              Leaderboard
            </Link>
            <Link to="/streak" className="text-blue-500 hover:text-blue-700 transition-colors duration-200">
              Streak
            </Link>
            {/* <Link to="/profile" className="text-blue-500 hover:text-blue-700 transition-colors duration-200">
              Profile
            </Link> */}
          </div>

          <button className="sm:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {isMenuOpen && (
          <div className="sm:hidden py-4 border-t border-gray-200">
            <nav className="flex flex-col space-y-2">
              <Link 
                to="/courses" 
                className="text-blue-500 hover:text-blue-700 transition-colors duration-200 py-2"
                onClick={toggleMenu}
              >
                Courses
              </Link>
              <Link 
                to="/leaderboard" 
                className="text-blue-500 hover:text-blue-700 transition-colors duration-200 py-2"
                onClick={toggleMenu}
              >
                Leaderboard
              </Link>
              <Link 
                to="/streak" 
                className="text-blue-500 hover:text-blue-700 transition-colors duration-200 py-2"
                onClick={toggleMenu}
              >
                Streak
              </Link>

              {/* <Link 
                to="/profile" 
                className="text-blue-500 hover:text-blue-700 transition-colors duration-200 py-2"
                onClick={toggleMenu}
              >
                Profile
              </Link> */}
            </nav>
          </div>
        )}
      </div>
    </header>
  )
}