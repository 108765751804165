'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Terminal, Code, RefreshCw, Play, Maximize2, Minimize2, Search, X, Check, LoaderIcon, TerminalIcon, Bug } from 'lucide-react'
import parse from 'html-react-parser'
import axios from 'axios'
import ReactConfetti from 'react-confetti'
import Editor from '@monaco-editor/react'
import DevToolsModal from '../../Components/Modals/DevToolsModal'
import { debounce } from 'lodash'
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader'
import { DraggableCore } from 'react-draggable'
import options from '../../Components/Lessons/OptionsQuill'
import VerificationResult from '../../Components/Lessons/WebDev/VerificationResult'

const Button = React.memo(({ children, onClick, disabled, className, icon: Icon }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={16} className="mr-2" />}
    {children}
  </button>
))

const Tab = React.memo(({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium border-b-2 ${
      active
        ? 'text-blue-600 border-blue-500'
        : 'text-gray-600 hover:text-gray-800 border-transparent'
    }`}
  >
    {children}
  </button>
))

export default function WebDevLesson({ lesson }) {
  const [activeSection, setActiveSection] = useState('instructions')
  const [activeCodeTab, setActiveCodeTab] = useState('html')
  const [html, setHtml] = useState(lesson.starter_code_html || '')
  const [css, setCss] = useState(lesson.starter_code_css || '')
  const [javascript, setJavascript] = useState(lesson.starter_code_js || '')
  const [output, setOutput] = useState('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [verificationResult, setVerificationResult] = useState(null)
  const [isVerifying, setIsVerifying] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [error, setError] = useState(null)
  const [splitPosition, setSplitPosition] = useState(50)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

  const editorRef = useRef(null)
  const containerRef = useRef(null)
  const dragRef = useRef(null)
  const resizeTimeoutRef = useRef(null)

  const handleResize = useCallback(
    debounce(() => {
      if (editorRef.current) {
        editorRef.current.layout()
      }
    }, 100),
    []
  )

  useEffect(() => {
    updateOutput()
  }, [html, css, javascript])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current)
          }
          resizeTimeoutRef.current = setTimeout(() => {
            handleResize()
          }, 100)
        }
      }
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [handleResize])

  const updateOutput = () => {
    const combinedOutput = `
      <html>
        <head>
          <style>${css}</style>
        </head>
        <body>${html}
          <script>${javascript}</script>
        </body>
      </html>
    `
    setOutput(combinedOutput)
  }

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev)
  }, [])

  const openInspectElementModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const verifyCode = async () => {
    setIsVerifying(true)
    setVerificationResult(null)
    setError(null)
    try {
      const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/compile/verify-html-css', {
        html: html,
        css: css,
        js: javascript,
        expectedOutput: lesson.sample_output
      })

      setVerificationResult(response.data)
      if (response.data.correct) {
        setShowConfetti(true)
        setTimeout(() => setShowConfetti(false), 5000)
      }
      setIsVerificationModalOpen(true)
    } catch (error) {
      console.error('Error verifying code:', error)
      setVerificationResult({
        correct: false,
        message: error.response.data.message,
        output: ''
      })
      setIsVerificationModalOpen(true)
    }
    setIsVerifying(false)
  }

  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor
    setTimeout(() => {
      editor.layout()
    }, 0)
  }

  const handleDragStart = useCallback((e) => {
    setIsDragging(true)
    setStartX(e.clientX)
  }, [])

  const handleDrag = useCallback((e) => {
    if (!isDragging) return
    
    const containerRect = containerRef.current.getBoundingClientRect()
    const containerWidth = containerRect.width
    const mouseX = e.clientX - containerRect.left
    const newPosition = (mouseX / containerWidth) * 100
    setSplitPosition(Math.min(Math.max(newPosition, 20), 80))
  }, [isDragging])

  const handleDragStop = useCallback(() => {
    setIsDragging(false)
    if (editorRef.current) {
      editorRef.current.layout()
    }
  }, [])

  const renderCodeEditor = () => {
    return (
      <div className="flex-1" style={{ overflow: 'hidden' }}>
        <div
          className={`relative`}
          style={{ height: '75vh', overflow: 'hidden' }}
        >
          <Editor
            height="100%"
            language={activeCodeTab === 'html' ? 'html' : activeCodeTab === 'css' ? 'css' : 'javascript'} // Handle JS tab
            value={activeCodeTab === 'html' ? html : activeCodeTab === 'css' ? css : javascript}
            onChange={(value) =>
              activeCodeTab === 'html'
                ? setHtml(value)
                : activeCodeTab === 'css'
                ? setCss(value)
                
                : setJavascript(value) // Set JavaScript value
            }
            theme="vs-light"
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 14,
              lineNumbers: 'on',
              renderLineHighlight: 'all',
              tabSize: 2,
              automaticLayout: false,
            }}
            onMount={handleEditorMount}
          />
        </div>
      </div>
    );
  };

  const renderOutput = (title, content, styling, extra) => (
    <div className={`flex-1 h-full ${extra}`}>
      <div className="border border-gray-200 rounded-md overflow-hidden h-full">
        <div className="bg-gray-100 px-4 py-2 flex items-center space-x-1 border-b border-gray-200">
          <div className="w-2 h-2 rounded-full bg-red-500"></div>
          <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
          <div className="w-2 h-2 rounded-full bg-green-500"></div>
        </div>
        <iframe
          srcDoc={content}
          title={title.toLowerCase()}
          sandbox="allow-scripts"
          className={`w-full max-h-[calc(100vh-10rem)] ${styling} bg-white`}
        />
      </div>
    </div>
  )


  return (
    <div className="bg-white text-gray-800 font-sans min-h-screen flex flex-col">
      {showConfetti && <ReactConfetti />}
      <CodeForCambodiaHeader />
      <header className="bg-gray-100 p-2 flex items-center space-x-2 border-b border-gray-200 sticky top-0 z-10">
        <div className="flex space-x-1">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <h1 className="text-sm flex-grow text-center font-semibold">{lesson.lesson_name}</h1>
      </header>

      <div className={`flex flex-grow ${isFullScreen ? 'fixed inset-0 z-50 bg-white' : ''}`}>
        {!isFullScreen && (
          <nav className={`bg-gray-100 w-16 md:w-48 flex flex-col border-r border-gray-200 sticky ${verificationResult ? 'h-[100vh]' : 'h-[90vh]'}`}>
            {[
              { id: 'instructions', icon: Terminal, label: 'Instructions' },
              { id: 'code', icon: Code, label: 'Code' },
              { id: 'sample', icon: Play, label: 'Sample' }
            ].map(({ id, icon: Icon, label }) => (
              <Button
                key={id}
                onClick={() => setActiveSection(id)}
                className={`justify-start w-full py-3 ${
                  activeSection === id ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-200'
                }`}
                icon={Icon}
              >
                <span className="hidden md:inline">{label}</span>
              </Button>
            ))}
          </nav>
        )}

        <main className={`flex-grow p-4 pt-0 overflow-hidden ${isFullScreen ? 'h-screen' : 'h-full'}`} ref={containerRef}>
          {activeSection === 'instructions' && !isFullScreen && (
            <div className="space-y-4 overflow-auto h-full Nokora pt-4 pb-8">
              {lesson?.instructions ? (
                <article className="prose prose-sm sm:prose lg:prose-lg max-w-none ">
                  {parse(lesson.instructions, options)}
                </article>
              ) : (
                <div className="flex items-center justify-center h-32 sm:h-64 bg-gray-100 rounded">
                  <p className="text-gray-600 italic text-sm sm:text-base">
                    No content available for this lesson.
                  </p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'code' && (
            <div className="space-y-4 h-full flex flex-col">
              <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                <div className="flex">
                  {['html', 'css', 'js'].map((tab) => (
                    <Tab
                      key={tab}
                      active={activeCodeTab === tab}
                      onClick={() => setActiveCodeTab(tab)}
                    >
                      {tab.toUpperCase()}
                    </Tab>
                  ))}
                </div>
                <div className="flex items-center ">
                  <Button
                    onClick={verifyCode}
                    disabled={isVerifying}
                    className={`
                      font-mono text-sm py-2 rounded-sm
                      transition-all duration-200 ease-in-out
                      
                      ${isVerifying 
                        ? 'bg-zinc-100 text-zinc-400 cursor-not-allowed' 
                        : 'bg-white text-zinc-800 hover:bg-zinc-100 hover:border-zinc-400 active:bg-zinc-200'
                      }
                      focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-opacity-50
                    `}
                  >
                    <div className="flex items-center justify-center space-x-2">
                      {isVerifying ? (
                        <LoaderIcon className="w-4 h-4 animate-spin" />
                      ) : (
                        <TerminalIcon className="w-4 h-4" />
                      )}
                      <span className="hidden sm:inline">
                        {isVerifying ? 'Verifying...' : 'Verify Code'}
                      </span>
                    </div>
                  </Button>
                  <Button
                    onClick={() => window.open('https://t.me/+EaKiMA8GiM0wMjI1', '_blank')}
                    className="text-gray-600 hover:bg-gray-200 Nokora"
                    icon={Bug}
                  >
                    មានបញ្ហា
                  </Button>
                  
                  <Button
                    onClick={openInspectElementModal}
                    className="text-gray-600 hover:bg-gray-200 font-mono"
                    title="Open Inspect Element"
                    icon={Search}
                  >
                      DevTools 
                  </Button>
                  <Button
                    onClick={() => {
                      setHtml(lesson.starter_code_html || '');
                      setCss(lesson.starter_code_css || '');
                      setJavascript(lesson.starter_code_js || '');
                      setVerificationResult(null);
                      setError(null);
                    }}
                    className="text-gray-600 hover:bg-gray-200 font-mono"
                    icon={RefreshCw}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={toggleFullScreen}
                    className="text-gray-600 hover:bg-gray-200"
                    icon={isFullScreen ? Minimize2 : Maximize2}
                  />
                </div>
              </div>
              <div className="flex flex-1 space-x-4 relative overflow-hidden pb-12">
                <div style={{ width: `${splitPosition}%` }} className="h-full">
                  {renderCodeEditor()}
                </div>
                <DraggableCore
                  onStart={handleDragStart}
                  onDrag={handleDrag}
                  onStop={handleDragStop}
                  nodeRef={dragRef}
                >
                  <div
                    ref={dragRef}
                    className="absolute top-0 bottom-0  bg-gray-100 cursor-col-resize flex items-center justify-center"
                    style={{ left: `calc(${splitPosition}% - 8px)` }}
                  >
                    <div className="w-1 h-8 bg-gray-400 rounded"></div>
                  </div>
                </DraggableCore>
                <div style={{ width: `${100 - splitPosition}%` }} className="h-full">
                  {renderOutput('Your Output', output, 'h-full')}
                </div>
              </div>
              <VerificationResult verificationResult={verificationResult} error={error} 
                isOpen={isVerificationModalOpen}
                onClose={() => setIsVerificationModalOpen(false)}
              />
            </div>
          )}

          {activeSection === 'sample' && !isFullScreen && (
            <div className="h-[calc(75vh)] pt-4">
              {renderOutput('Sample Output', lesson.sample_output, 'h-full')}
            </div>
          )}
        </main>
      </div>

      {isModalOpen && (
        <DevToolsModal isOpen={isModalOpen} onClose={closeModal} />
      )}
    </div>
  )
}