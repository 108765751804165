'use client'

import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { MessageCircle, X, Copy, Check, Globe } from "lucide-react"
import Draggable from 'react-draggable'; // Import Draggable

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [lastActive, setLastActive] = useState(null)
  const [language, setLanguage] = useState('kh') // Default to Khmer
  const scrollAreaRef = useRef(null)
  const inputRef = useRef(null)

  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }

  useEffect(() => {
    const deviceId = localStorage.getItem('deviceId')
    if(deviceId){
      axios.post(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/last-active`, {
        device_id: deviceId
      })
      .then(response => {
        setLastActive(response.data.lastActive);
      })
    }
  }, [])

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const handleSend = async (e) => {
    e.preventDefault()
    if (input.trim() && !isLoading) {
      const newMessage = { id: messages.length + 1, content: input, sender: 'user' }
      setInput('')
      setIsLoading(true)

      try {
        const chatHistory = messages.map(msg => ({
          role: msg.sender,
          content: msg.content + `. Reply in ${language === 'en' ? 'English' : 'Khmer'}`
        }))
        chatHistory.push({ role: 'user', content: input })

        const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/claude/', 
          { 
            messages: chatHistory,
            language: language
          }, 
          { headers }
        )
        
        setMessages(prevMessages => [
          ...prevMessages,
          newMessage,
          { 
            id: prevMessages.length + 2, 
            content: response.data.response, 
            sender: 'assistant'
          }
        ])
      } catch (error) {
        console.error('Error getting response from Claude:', error)
        const errorMessage = {
          id: messages.length + 2,
          content: "Sorry, I encountered an error. Please try again.",
          sender: 'assistant'
        }
        setMessages(prevMessages => [...prevMessages, newMessage, errorMessage])
      } finally {
        setIsLoading(false)
      }
    }
  }

  const CodeBlock = ({ code }) => {
    const [isCopied, setIsCopied] = useState(false);
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(code).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    };
  
    return (
      <div className="bg-gray-100 rounded-md p-4 relative mt-2 mb-2 border border-gray-300">
        <button
          onClick={copyToClipboard}
          className="absolute top-2 right-2 bg-transparent border-none cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          {isCopied ? <Check size={16} className="text-green-500" /> : <Copy size={16} className="text-gray-500" />}
        </button>
        <pre className="m-0 text-sm text-gray-800 overflow-x-auto">
          <code>{code.trim()}</code>
        </pre>
      </div>
    );
  };
  

  const renderMessage = (message) => {
    const codeBlockRegex = /```([\s\S]*?)```/g;
    const parts = message.content.split(codeBlockRegex);
  
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a code block, render the CodeBlock component
        return <CodeBlock key={index} code={part} />;
      } else {
        // This is normal text, render it as a span
        return <span key={index}>{part}</span>;
      }
    });
  };
  

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'en' ? 'kh' : 'en')
  }

  return (
    <div className='Nokora'>
      <Draggable>
      <button
        className={` 
          fixed z-50 transition-all duration-300 ease-in-out
          ${isOpen ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'}
          text-white font-semibold rounded-full shadow-lg
          flex items-center justify-center
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          bottom-20 right-4 w-12 h-12 md:w-auto md:h-auto md:px-4 md:py-2 md:rounded-lg
        `}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <>
            <X size={20} className="md:mr-2" />
            <span className="hidden md:inline text-sm">Close</span>
          </>
        ) : (
          <>
            <MessageCircle size={20} className="md:mr-2" />
            <span className="hidden md:inline text-sm">CodeBot</span>
          </>
        )}
      </button>
      </Draggable>

      {isOpen && (
        <Draggable>

        <div className="absolute bottom-32 right-4 w-80 md:w-96 h-[28rem] bg-white rounded-lg shadow-xl overflow-hidden z-50 border border-gray-200 flex flex-col">
          <div className="bg-gray-100 p-3 flex justify-between items-center border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800">CodeBot Terminal</h2>
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleLanguage}
                className="text-gray-600 hover:text-gray-800 focus:outline-none flex items-center"
                title={language === 'en' ? 'Switch to Khmer' : 'Switch to English'}
              >
                <Globe size={18} />
                <span className="ml-1 text-sm font-medium">{language.toUpperCase()}</span>
              </button>
              <div className="flex space-x-2">
                <div onClick={() => setIsOpen(false)} className="w-3 h-3 rounded-full bg-red-500 cursor-pointer"></div>
                <div onClick={() => setIsOpen(false)} className="w-3 h-3 rounded-full bg-yellow-500 cursor-pointer"></div>
                <div onClick={() => setIsOpen(false)} className="w-3 h-3 rounded-full bg-green-500 cursor-pointer"></div>
              </div>
            </div>
          </div>
          <div 
            ref={scrollAreaRef}
            className="flex-grow overflow-y-auto p-4 bg-white"
          >
            {messages.length === 0 ? (
              <div className="text-gray-500 mt-8 font-nokora">
                <p className="mb-2">Last login: {lastActive ? new Date(lastActive).toLocaleString() : 'Never'}</p>
                <p>{language === 'en' 
                  ? "Welcome to CodeBot Terminal! Ask me anything about programming."
                  : "សូមស្វាគមន៍មកកាន់ CodeBot Terminal! សួរខ្ញុំអំពី programming គ្រប់មុខ។"}
                </p>
              </div>
            ) : (
              messages.map((message, index) => (
                <div key={message.id} className="flex mb-4">
                  <div className="w-8 text-gray-400 select-none text-right pr-2">
                    {(index + 1).toString().padStart(2, '0')}
                  </div>
                  <div className="flex-1">
                    <span className={message.sender === 'user' ? 'text-blue-500' : 'text-green-500'}>
                      {message.sender === 'user' ? '$ ' : ''}
                    </span>
                    <span className={`whitespace-pre-wrap break-words ${message.sender === 'user' ? 'text-blue-700' : 'text-gray-800'}`}>
                      {renderMessage(message)}
                    </span>
                  </div>
                </div>
                
              ))
              
            )}
          </div>
          <form onSubmit={handleSend} className="p-3 bg-gray-100 border-t border-gray-200">
            <div className="flex items-center">
              <span className="text-blue-500 mr-2 text-lg font-bold">$</span>
              <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={language === 'en' ? "Type your command..." : "សរសេរសំណួររបស់អ្នក..."}
                className="flex-grow bg-white border border-gray-300 rounded-l-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isLoading}
              />
              <button 
                type="submit" 
                className="bg-blue-500 text-white px-4 py-2 rounded-r-md text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? (language === 'en' ? 'Processing...' : 'កំពុងដំណើរការ...') : (language === 'en' ? 'Run' : 'ដំណើរការ')}
              </button>
            </div>
          </form>
        </div>
        </Draggable>
      )}
    </div>
  )
}

export default ChatBot